<template>
  <div>
    <VCard>
      <VCardTitle>
        <div
          class="float-left"
          :class="{'btn-table-align': hasMonthlyAndYearlyPlans}"
        >
          Update Subscription
        </div>
        <!-- Interval Selector Button Group -->
        <div class="float-right">
          <div
            v-if="hasMonthlyAndYearlyPlans"
            class="btn-group btn-group-sm"
          >
            <!-- Monthly Plans -->
            <VBtn
              type="light"
              :class="{'active': showingMonthlyPlans}"
              @click="showMonthlyPlans"
            >
              Monthly
            </VBtn>
            <!-- Yearly Plans -->
            <VBtn
              type="light"
              :class="{'active': showingYearlyPlans}"
              @click="showYearlyPlans"
            >
              Yearly
            </VBtn>
          </div>
        </div>
        <div class="clearfix" />
      </VCardTitle>
      <VCardText>
        <!-- Plan Error Message - In General Will Never Be Shown -->
        <VAlert
          v-if="planForm.errors.has('plan')"
          :value="true"
          color="error"
        >
          {{ planForm.errors.get('plan') }}
        </VAlert>
        <!-- Current Subscription (Active) -->
        <VAlert
          v-if="activePlan.active"
          color="info"
          :value="true"
          class="m-4"
        >
          You are currently subscribed to the {{ activePlan.name }} plan.
        </VAlert>
        <!-- Current Subscription (Archived) -->
        <VAlert
          v-if=" ! activePlan.active"
          color="warning"
          class="alert alert-warning m-4"
        >
          You are currently subscribed to the {{ activePlan.name }} ({{ activePlan.interval.capitalize() }} plan.
          This plan has been discontinued, but you may continue your subscription to this plan as long as you
          wish. If you cancel your subscription and later want to begin a new subscription, you will need to
          choose from one of the active plans listed below.
        </VAlert>
        <!-- European VAT Notice -->
        <template v-if="$spark.collectsEuropeanVat">
          <p class="m-4">
            All subscription plan prices include applicable VAT.
          </p>
        </template>
      </VCardText>
      <VRow
        row
        wrap
      >
        <VCol
          v-for="(plan, key) in plansForActiveInterval"
          :key="key"
        >
          <VCard
            :raised="isActivePlan(plan)"
            :color="isActivePlan(plan) ? 'success': 'default'"
          >
            <!-- Plan Name -->
            <VCardActions class="row justify-center">
              <VBtn
                :color="isActivePlan(plan) ? 'success' : 'primary'"
                :disabled="isActivePlan(plan)"
                :loading="selectingPlan && selectingPlan === plan"
                @click="confirmPlanUpdate(plan)"
              >
                <span v-if="selectingPlan && selectingPlan === plan">
                  Updating
                </span>
                <span v-else-if="isActivePlan(plan)">
                  Current Plan
                </span>
                <span v-else>
                  Select This Plan
                </span>
              </VBtn>
            </VCardActions>
            <VCardTitle>
              <h1
                class="text-sm-center"
                style="width: 100%"
              >
                {{ plan.name }}
              </h1>
            </VCardTitle>
            <VCardTitle>
              <h1
                class="text-sm-center"
                style="width: 100%"
              >
                <span v-if="plan.price == 0">
                  Free
                </span>
                <span v-else>
                  {{ $spark.currencySymbol }}{{ plan.price }}
                </span>
                <template v-if="plan.price > 0">
                  {{ plan.type == 'user' && $spark.chargesUsersPerSeat ? '/ '+ $spark.seatName : '' }}
                  {{ plan.type == 'user' && $spark.chargesUsersPerTeam ? '/ '+ Team : '' }}
                  {{ plan.type == 'team' && $spark.chargesTeamsPerSeat ? '/ '+ $spark.teamSeatName : ''
                  }}
                  {{ plan.type == 'team' && $spark.chargesTeamsPerMember ? '/ '+ $team.user_role_name :
                    '' }}
                  {{ plan.interval.capitalize() }}
                </template>
              </h1>
            </VCardTitle>
            <VCardText v-if="plan.trialDays && ! hasSubscribed(plan)">
              <!-- Trial Days -->
              <h3>{{ plan.trialDays }} Day Trial</h3>
            </VCardText>
            <VDivider />
            <!-- Plan Price -->
            <VCardText>
              <VList>
                <VListItem
                  v-for="(feature, featureKey) in plan.features"
                  :key="featureKey"
                >
                  <VListItemContent>
                    <VListItemTitle class="text-sm-center">
                      {{ feature }}
                    </VListItemTitle>
                  </VListItemContent>
                </VListItem>
              </VList>
            </VCardText>
            <VDivider />
            <VCardActions class="row justify-center">
              <VBtn
                :color="isActivePlan(plan) ? 'success' : 'primary'"
                :disabled="isActivePlan(plan)"
                :loading="selectingPlan && selectingPlan === plan"
                @click="confirmPlanUpdate(plan)"
              >
                <span v-if="selectingPlan && selectingPlan === plan">
                  Updating
                </span>
                <span v-else-if="isActivePlan(plan)">
                  Current Plan
                </span>
                <span v-else>
                  Select This Plan
                </span>
              </VBtn>
            </VCardActions>
          </VCard>
        </VCol>
      </VRow>
    </VCard>
    <!-- Confirm Plan Update Modal -->
    <VDialog
      v-if="dialogs.confirmPlan"
      id="modal-confirm-plan-update"
      max-width="500"
      tabindex="-2"
      title="Update Subscription"
      v-model="dialogs.confirmPlan"
    >
      <VCard>
        <VCardTitle>Switch Plan</VCardTitle>
        <!-- Modal Body -->
        <VCardText>
          Are you sure you want to switch to the
          <strong>
            {{ confirmingPlan.name }} plan
            {{ confirmingPlan.interval.capitalize() }}
          </strong>
        </VCardText>
        <!-- Modal Actions -->
        <VCardActions class="justify-end">
          <VBtn
            text
            color="secondary"
            @click="dialogs.confirmPlan = false"
          >
            No, Go Back
          </VBtn>
          <VBtn
            color="success"
            @click="approvePlanUpdate"
          >
            Yes, I'm Sure
          </VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import PlansMixin from "@/mixins/Plans";
import SubscriptionMixin from "@/mixins/Subscriptions";

export default {
  /**
   * Load mixins for the component.
   */
  mixins: [PlansMixin, SubscriptionMixin],
  props: {
    plans: {
      type: Array,
      default: () => []
    }
  },

  /**
   * The component's data.
   */
  data() {
    return {
      confirmingPlan: null
    };
  },

  /**
   * Prepare the component.
   */
  created() {
    this.selectActivePlanInterval();

    // We need to watch the activePlan computed property for changes so we can select
    // the proper active plan on the plan interval v-btn group. So, we will watch
    // this property and fire off a method anytime it changes so it can sync up.
    this.$watch("activePlan", value => {
      this.selectActivePlanInterval();
    });

    if (this.onlyHasYearlyPlans) {
      this.showYearlyPlans();
    }
  },

  methods: {
    /**
     * Confirm the plan update with the user.
     */
    confirmPlanUpdate(plan) {
      this.confirmingPlan = plan;

      this.dialogs.confirmPlan = true;
    },

    /**
     * Approve the plan update.
     */
    approvePlanUpdate() {
      this.dialogs.confirmPlan = false;

      this.updateSubscription(this.confirmingPlan);
    },

    /**
     * Select the active plan interval.
     */
    selectActivePlanInterval() {
      if (this.activePlanIsMonthly || this.yearlyPlans.length == 0) {
        this.showMonthlyPlans();
      } else {
        this.showYearlyPlans();
      }
    },

    /**
     * Show the plan details for the given plan.
     *
     * We'll ask the parent subscription component to display it.
     */
    showPlanDetails(plan) {
      this.$parent.$emit("showPlanDetails", plan);
    },

    /**
     * Get the plan price with the applicable VAT.
     */
    priceWithTax(plan) {
      return plan.price + plan.price * (this.billable.tax_rate / 100);
    }
  }
};
</script>
