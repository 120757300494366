<template>
  <VApp
    :class="{
      wethrive: true,
      'select-team': dialogs.selectTeam,
    }"
  >
    <template v-if="$auth.ready() && $hasUser && isFullyLoaded">
      <Notifications
        v-if="hasMessagesOrErrors"
        :alt-view="altView"
        :messages="messages"
        :errors="errors"
        :timeout="notificationTimeout"
      />
      <VDialog
        v-model="drawer"
        fullscreen
        origin="top left"
        transition="fade-transition"
      >
        <VContainer
          fluid
          id="side-menu"
          class="pt-0"
          style="background-color: rgba(33, 33, 33, 0.46)"
          @click="drawer = !drawer"
        >
          <VRow>
            <VCol>
              <VAppBarNavIcon
                icon
                class="wethrive"
                width="40"
                height="40"
                @click.stop="drawer = !drawer"
              >
                <VIcon color="white">
                  menu
                </VIcon>
              </VAppBarNavIcon>
            </VCol>
          </VRow>
          <MainMenu />
        </VContainer>
      </VDialog>
      <FeedNotificationPanel
        id="notification-panel"
        :active="dialogs.notificationDrawer"
        :channels="[`users.${$user.id}`]"
        @toggle="onToggleNotifications"
        @input="onToggleNotifications"
      />
      <UserMenuPanel
        id="user-menu-panel"
        :items="menuProfileItems"
        :active="dialogs.profileMenu"
        @toggle="onToggleProfileMenu"
        @toggleNotifications="onToggleNotifications"
      />
      <VAppBar
        app
        fixed
        row
        wrap
        align-center
        flat
        max-height="56px"
        id="main-content-menu"
        :style="[$isUserApproved ? '' : navBarStyle]"
      >
        <TeamMenuActivator
          bottom
          button-classes="ml-n3"
          @click="onToggleTeamsDialog"
        />
        <VList
          v-if="$isUserApproved"
          dense
          color="transparent"
          id="top-left-menu"
          class="d-flex ml-4"
        >
          <VListItem
            v-for="(item, index) in topLeftMenuItems"
            :key="`top-left-menu-item-${index}`"
            :to="item.route"
            :class="{
              'active-top-left-menu': item.active,
            }"
            @click="$router.push(item.route)"
          >
            <VListItemContent>
              <VListItemTitle
                class="subtitle-1"
                style="font-weight: 400;"
              >
                {{ item.title }}
              </VListItemTitle>
            </VListItemContent>
          </VListItem>
        </VList>
        <VSpacer />
        <VRow
          v-if="$vuetify.breakpoint.mdAndUp"
          align-center
        >
          <VCol
            elevation="0"
            text
          >
            <!--<TextField
            id="main-content-menu-search" textlabel="Find an individual or team..." solo-inverted>
                <img src="./../images/search-icon.svg" slot="prepend" />
            </TextField>-->
          </VCol>
        </VRow>
        <VSpacer />
        <VRow
          row
          wrap
          justify-end
          align-center
          id="profile-menu"
          :style="{
            marginRight: $vuetify.breakpoint.mdAndUp ? '20px' : '0px',
          }"
        >
          <VCol>
            <VRow
              row
              justify-end
              align-center
            >
              <!--<VBtn color="primary" text icon @click="navigateTo('dashboard')">
                <img :src="require('../images/dashboard icon active.png')">
              </VBtn>-->
              <GlobalSearch v-if="isEnabledFeature('global_search')" />
              <template v-if="$vuetify.breakpoint.mdAndUp">
                <VBtn
                  height="40px"
                  :class="{
                    'mr-2': true,
                    'white--text': true,
                  }"
                  :style="{
                    boxShadow: '0px 4px 4px 0 rgba(255, 115, 78, 0.25)',
                    backgroundImage:
                      'linear-gradient(to top, #ff4f45, #ff8752)',
                    fontSize: '1.5rem !important',
                    borderRadius: '10px',
                  }"
                  v-if="
                    !$isUserApproved &&
                      !$team.is_enterprise &&
                      $team.enable_customization
                  "
                  @click="navigateTo({ name: 'lesson.index' })"
                >
                  Learn More
                </VBtn>
                <VBtn
                  x-small
                  width="40px"
                  height="40px"
                  :class="{
                    'mr-2 px-0': true,
                  }"
                  :style="{
                    backgroundColor: $intercom.visible ? '#ddd' : '#fff',
                  }"
                  v-if="
                    $isUserApproved ||
                      (!$isUserApproved &&
                        !$team.is_enterprise &&
                        $team.enable_customization)
                  "
                  @click="onToggleIntercom"
                >
                  <VIcon>
                    mdi-help
                  </VIcon>
                </VBtn>
                <ChatNotification
                  v-if="$isUserApproved"
                  class="mr-3"
                  mode="link"
                  :channels="[`chat.${$user.id}`]"
                />
                <VTooltip
                  v-if="isEnabledFeature('calendar')"
                  bottom
                  class="mx-1"
                >
                  <template #activator="{ on }">
                    <VBtn
                      v-on="on"
                      text
                      icon
                      slot="activator"
                      color="primary"
                      width="40px"
                      height="40px"
                      @click="
                        navigateTo({
                          name: 'calendar.index',
                          hash: $user.new_rsvps_count ? '#rsvps' : '',
                        })
                      "
                    >
                      <!-- <img
                  style="margin-left: 6px; width: 14px"
                  src="./../images/calendar.png"
                    >-->
                      <Badge
                        top
                        right
                        overlap
                        offset-y="50%"
                        color="primary"
                        :value="$user.new_rsvps_count > 0"
                      >
                        <template #badge>
                          !
                        </template>
                        <VIcon
                          large
                          color="primary"
                          style="font-size: 24px"
                          :size="24"
                        >
                          calendar_today
                        </VIcon>
                      </Badge>
                    </VBtn>
                  </template>
                  <span>
                    <template
                      v-if="$user.new_rsvps_count > 0"
                    >You have new RSVPs!</template>
                    <template
                      v-else
                    >No new RSVPs</template>
                  </span>
                </VTooltip>
                <FeedNotification
                  v-if="
                    $isUserApproved ||
                      (!$isUserApproved &&
                        !$team.is_enterprise &&
                        $team.enable_customization)
                  "
                  class="mr-2"
                  mode="link"
                  :channels="[`users.${$user.id}`]"
                  @toggle="onToggleNotifications(null)"
                />
              </template>
              <VBtn
                text
                icon
                width="40px"
                height="40px"
                color="white"
                class="ml-1"
                @click="dialogs.profileMenu = !dialogs.profileMenu"
              >
                <VAvatar
                  style="border-radius: 10px; cursor: pointer"
                  size="40"
                >
                  <img :src="userAvatar">
                </VAvatar>
              </VBtn>
            </VRow>
          </VCol>
        </VRow>
      </VAppBar>
      <VMain
        id="main-content-content"
        class="overflow-hidden"
        :style="globalStyle"
      >
        <VRow class="my-0">
          <VCol
            v-if="$showLeftMainMenu && $isUserApproved"
            cols="auto"
            class="justify-left"
          >
            <LeftSideMainMenu />
          </VCol>
          <VCol
            style="height: calc(100vh - 66px)"
            :class="{
              'justify-right overflow-x-hidden overflow-y-auto py-0': true,
              'pl-0': $showLeftMainMenu && $isUserApproved,
            }"
          >
            <VContainer
              fill-height
              fluid
              py-0
              pr-8
              class="overflow-x-hidden"
              :pl-8="!($showLeftMainMenu && $isUserApproved)"
              :pb-6="$vuetify.breakpoint.smAndDown"
              :px-0="
                ($team.enable_customization &&
                  !$isUserApproved &&
                  $route.name == 'dashboard') ||
                  $vuetify.breakpoint.smAndDown
              "
              :px-2="$vuetify.breakpoint.smAndDown"
              :align-start="$hasUser"
              :align-center="!$hasUser"
            >
              <slot
                v-if="$hasUser"
                name="main-content"
              />
              <VProgressLinear
                v-else
                indeterminate
                color="primary"
                class="mb-0"
              />
            </VContainer>
          </VCol>
        </VRow>
      </VMain>
      <SelectTeam
        :select-team="dialogs.selectTeam"
        @close="onToggleTeamsDialog"
      />
      <LoginModal
        :is-dialog-open="dialogs.login"
        @toggle:dialog="onToggleLoginDialog"
        @loggedIn="onToggleLoginDialog"
      />
      <VBottomSheet
        v-model="dialogs.uploadProgress.dialog"
        persistent
        hide-overlay
        style="margin-bottom: 20px; border-radius: 0px"
        width="100%"
      >
        <VCard style="border-radius:0px">
          <VCardText class="px-3">
            <div class="d-flex justify-space-between">
              <span
                v-if="dialogs.uploadProgress.value < 100"
                class="px-1 py-0"
              >Uploading. Please wait...<strong>{{ Math.ceil(dialogs.uploadProgress.value) }}%</strong></span>
              <span
                v-else
                class="px-1 py-0"
              >Processing. Please wait...</span>
              <VBtn
                text
                small
                class="error"
                @click="onToggleUploadDialog"
              >
                Cancel
              </VBtn>
            </div>
            <VProgressLinear
              stream
              rounded
              class="px-2 py-1 my-2"
              height="10"
              :buffer-value="dialogs.uploadProgress.value"
            />
          </VCardText>
        </VCard>
      </VBottomSheet>
    </template>
    <VRow
      v-else
      row
      fluid
      justify-center
      align-center
      fill-height
    >
      <VProgressCircular
        indeterminate
        rounded
        stream
        color="primary"
      />
    </VRow>
  </VApp>
</template>

<script>
import Vue from "vue";
import Echo from "vue-echo";
import NotificationMixin from "@/mixins/Notification";
// For applicant/enterprise portals
import HasActivityMixin from "@/mixins/Activity";
import LeftMainMenuMixin from "@/mixins/LeftMainMenu";
import ChatNotification from "@/components/Elements/Notifications/ChatNotification";
import FeedNotification from "@/components/Elements/Notifications/FeedNotification";
import FeedNotificationPanel from "@/components/Elements/Notifications/FeedNotificationPanel";
import UserMenuPanel from "@/components/Elements/Navigation/UserMenuPanel";
import SelectTeam from "@/components/Organization/SelectTeam";
import LoginModal from "@/components/Login/LoginModal";
import MainMenu from "@/components/Elements/Navigation/MainMenu";
import TeamMenuActivator from "@/components/Elements/Notifications/TeamMenuActivator";
import LeftSideMainMenu from "@/components/Elements/Navigation/LeftSideMainMenu";
import GlobalSearch from "@/components/Elements/GlobalSearch";
import { mapActions } from "vuex";

export default {
  name: "AppLayout",
  components: {
    ChatNotification,
    FeedNotification,
    FeedNotificationPanel,
    UserMenuPanel,
    SelectTeam,
    LoginModal,
    MainMenu,
    TeamMenuActivator,
    GlobalSearch,
    LeftSideMainMenu,
  },
  mixins: [NotificationMixin, HasActivityMixin, LeftMainMenuMixin],
  data() {
    return {
      profile: {},
      drawer: false,
      showSettings: false,
      mini: false,
      msg: "Welcome to WeThrive",
      needsToSetCurrentTeam: false,
      isFullyLoaded: false,
      dialogs: {
        selectTeam: false,
        notificationDrawer: false,
        profileMenu: false,
        login: false,
        uploadProgress: {
          value: 0,
          dialog: false,
        },
      },
    };
  },
  computed: {
    invitationsCount() {
      return this.$user.invitations_count || 0;
    },
    topLeftMenuItems() {
      return [
        {
          title: this.featureName("Programs"),
          active: this.activeMenuItem && this.activeMenuItem.category == "programSections",
          enabled: true,
          route: {
            name: 'program.index',
            params: {
              team: this.$team.slug,
            },
          },
        },
        {
          title: "Content",
          active: this.activeMenuItem && this.activeMenuItem.category == "features",
          enabled: this.$isUserApproved,
          route: { 
            name: "lesson.index",
            params: {
              team: this.$team.slug,
            },
          },
        },
        { 
          title: 'Admin',
          active: this.activeMenuItem && this.activeMenuItem.category == "adminSettings",
          enabled: this.$isUserAdmin || (this.$isUserMentor && this.$user.pipelines_count > 0),
          route: {
            name: 'settings',
            hash: '#pipelines',
          },
        },
      ].filter(item => item.enabled === true);
    },
    menuProfileItems() {
      return {
        user: [
          {
            title: `My ${this.featureName("Account")}`,
            icon: require("../images/account@3x.png"),
            routeName: "account",
            visible: true,
            children: [
              {
                title: `My ${this.featureName("Applications")}`,
                icon: require("../images/my-applications@3x.png"),
                route: {
                  name: "account",
                  hash: "#applications",
                },
              },
            ],
          },
        ],
        admin: [
          {
            title: this.featureName("Billing"),
            icon: require("../images/star-grey@3x.png"),
            routeName: "billing",
            visible: this.$isTeamOwner && this.isEnabledFeature("BILLING"),
            visible: false,
          },
          {
            title: `${this.featureName("Workspace Settings")}`,
            icon: require("../images/settings@3x.png"),
            routeName: "settings",
            visible: this.$isUserAdmin,
          },
          {
            title: this.featureName("Pipelines"),
            icon: require("../images/pipelines-icon@3x.png"),
            route: {
              name: "settings",
              hash: "#pipelines",
            },
            visible:
              this.isEnabledFeature("PIPELINES") &&
              (this.$isUserAdmin || this.$isUserMentor),

          },
          {
            title: "Automation Workflows",
            icon: require("../images/dashboard-icon@3x.png"),
            beta: this.isBetaFeature("AUTOMATIONS"),
            route: {
              name: "settings",
              hash: "#automations",
            },
            visible:
              this.$team.is_enterprise &&
              this.isEnabledFeature("AUTOMATIONS") &&
              this.$isUserAdmin,
          },
        ].filter((item) => item.visible === true),
        support: [
          {
            header: this.featureName("Support"),
          },
          {
            title: this.featureName("About WeThrive"),
            href: "https://wethrive.io/",
            target: "_blank",
            icon: require("../images/aboutwethrive@3x.png"),
          },
          {
            title: this.featureName("Privacy Policy"),
            routeName: "privacy-policy",
            icon: require("../images/privacy@3x.png"),
          },
          {
            title: this.featureName("Terms"),
            routeName: "terms-and-conditions",
            icon: require("../images/terms@3x.png"),
          },
        ],
      };
    },
    userAvatar() {
      return this.$user ? this.$user.photo_url : "";
    },
    userName() {
      return this.$user ? this.$user.name : "Loading";
    },
    needsTeam() {
      return this.$team === undefined;
    },
    hasMessages() {
      return false;
    },
    intercom() {
      return this.$intercom;
    },
    hasSubmittedApplication() {
      return (
        this.$user.submitted_application instanceof Object &&
        this.$user.submitted_application.id
      );
    },
    globalStyle() {
      return this.$isUserApproved
        ? {}
        : {
            marginTop: this.$route.name == "dashboard" ? "0px" : "56px",
            maxHeight:
              this.$route.name == "dashboard" ? "100vh" : "calc(100vh - 56px)",
            backgroundColor: this.themeSetting(
              "applicantPortal_background_color"
            ),
          };
    },
    navBarStyle() {
      return this.$isUserApproved
        ? {}
        : {
            backgroundColor: this.themeSetting(
              "applicantPortal_background_color"
            ),
          };
    },
  },
  watch: {
    $hasUser: function(value) {
      if (value) {
        this.$log.debug("User logged in", this.$auth.check(), this.$user);
        // this.initIntercom();
        this.initEcho();
      }
    },
    $team: function(team) {
      if (team && team.id) {
        if (this.needsToSetCurrentTeam) {
          this.doSetCurrentTeam(team.id);
        }
        this.$timezone.setDefaultTimezone(team ? team.timezone : "");
      }
    },
    $user: function(user) {
      if(!this.$team.is_free && this.$isTeamOwner && !user.team.is_profile_complete) {
        this.$router.push({
          name: "register",
        });
      }
    },
    "$route.hash": function(hash) {
      switch (hash) {
        case "#/teams":
        case "#teams":
          this.dialogs.selectTeam = true;
          break;

        case "#notifications":
        case "#invitations":
          this.dialogs.selectTeam = true;
        case "#announcements":
          this.dialogs.notificationDrawer = true;
          break;
      }
    },
  },
  created() {
    if (!this.$auth.check()) {
      this.$log.debug("User not logged in", this.$auth.check());
      this.$auth.logout();
      this.$router.push({
        name: "login",
      });
    }
    this.$log.debug("User logged in", this.$auth.check());
    const ready = function() {
      this.$on("needsToSelectTeam", () => {
        if (!this.$hasAccessToCurrentTeam && this.$team && !this.$team.id) {
          this.dialogs.selectTeam = true;
        }
      });

      this.$bus.$on("needsToLogin", () => {
        this.doLogout();
        this.dialogs.login = true;
      });

      this.$bus.$on("toggleTeamsDialog", () => {
        this.onToggleTeamsDialog();
      });

      this.$bus.$on("toggleIntercom", () => {
        this.onToggleIntercom();
        this.onToggleProfileMenu();
      });

      this.$bus.$on("upload:progress", (value) => {
        this.onUpdateUploadProgress(value);
      });

      this.$bus.$on("upload:dialog", (value) => {
        this.onToggleUploadDialog(value);
      });

      this.setMe(this.$auth.user());
      this.checkNeedsTeam();

      if (["#teams", "#/teams"].indexOf(this.$route.hash) > -1) {
        this.dialogs.selectTeam = true;
      }
    };
    if (!this.$auth.ready()) {
      this.$auth.ready(ready);
    } else {
      ready.call(this);
    }

    this.initIntercom();
  },
  methods: {
    ...mapActions({
      doSetCurrentTeam: "user/setCurrentTeam",
      doLogout: "auth/logout",
      getMe: "user/me",
      setMe: "user/setMe",
    }),
    logout() {
      const params = {
        team: this.$team.slug,
      };
      this.doLogout().then(() => {
        this.$log.debug("App: Logout");
        this.$auth.token(null, "");
        this.$auth.logout({
          redirect: false,
        });
        window.location.href = "/login";
      });
    },
    checkNeedsTeam() {
      if (this.needsTeam && this.$route.name !== "settings") {
        this.$log.debug("Needs team?", this.needsTeam);
        this.$router.push({
          name: "settings",
          params: {
            state: "is-not-complete",
            team: this.$team ? this.$team.slug : "",
          },
        });
      }
      this.checkShouldSetCurrentTeam();
      if (!this.$hasTeams) {
        this.$emit("needsToSelectTeam");
      }

      this.checkHasAccessToTeam();
    },
    checkHasAccessToTeam() {
      this.$log.debug("Checking access to team");
      this.switchToTeamFromRouteIfNecessary(
        this.$route,
        { params: { team: this.$team.slug } },
        () => {}
      ).then((team) => {
        if (team instanceof Object) {
          this.isFullyLoaded =
            team.slug === (this.$route.params.team || this.$team.slug);
        }
      });
      // if (!this.$hasAccessToCurrentTeam && this.$route.name !== "account") {
      //   this.$router.push({
      //     name: "account",
      //     params: {
      //       state: "not-approved",
      //       team: this.$team ? this.$team.slug : ""
      //     }
      //   });
      // }
    },
    checkShouldSetCurrentTeam() {
      if (!this.$user.current_team_id && this.$team) {
        setTimeout(() => {
          this.doSetCurrentTeam(this.$team.id).then(
            (team) => (this.$team = team.data)
          );
        }, 1000);
      }
    },
    initIntercom() {
      this.$log.debug("[App]: Intercom: booting");
      this.$intercom.boot({
        userId: this.$user.id,
        user_id: this.$user.id,
        name: this.$user.name,
        email: this.$user.email,
        role: this.$user.role_name,
        user_hash: this.$user.hash,
        company_id: this.$user.current_team_id,
        company_website: this.$team.website,
      });
      this.$intercom.hide();
      this.$log.debug("[App]: Intercom: booted");
    },
    initEcho() {
      /** Update the pusher token since it may not be properly set */
      this.$echo.options.auth = this.$echo.options.auth || {
        headers: {},
      };
      this.$echo.connector.pusher.config =
        this.$echo.connector.pusher.config || this.$echo.options.auth;
      this.$echo.connector.pusher.config.auth.headers["Authorization"] =
        "Bearer " + this.$auth.token();
      this.$echo.options.auth.headers = this.$echo.connector.pusher.config.auth.headers;
    },
    onToggleIntercom() {
      if (!this.$intercom.visible) {
        this.$intercom.show();
      } else {
        this.$intercom.hide();
      }
    },
    onToggleProfileMenu() {
      this.dialogs.profileMenu = !this.dialogs.profileMenu;
    },
    onToggleNotifications(value) {
      if (value !== undefined && value !== null) {
        this.dialogs.notificationDrawer = value;
      } else {
        this.dialogs.notificationDrawer = !this.dialogs.notificationDrawer;
      }
      // this.$user.notification_announcement_count =
      //   this.dialogs.notificationDrawer === true
      //     ? 0
      //     : this.$user.notification_announcement_count;
      this.$log.debug(
        "Toggling notifications",
        this.dialogs.notificationDrawer,
        value
      );
    },
    onToggleLoginDialog() {
      this.dialogs.login = !this.dialogs.login;
    },
    onUpdateUploadProgress(value) {
      this.dialogs.uploadProgress.value = value;
    },
    onToggleUploadDialog(value) {
      if (value === false || value === true) {
        this.dialogs.uploadProgress.dialog = value;
        if (value === false) {
          this.dialogs.uploadProgress.value = 0;
        }
      } else {
        this.dialogs.uploadProgress.dialog = !this.dialogs.uploadProgress
          .dialog;
      }
    },
    onToggleTeamsDialog() {
      console.log("Toggling team dialog");
      this.dialogs.selectTeam = !this.dialogs.selectTeam;
      if (
        !this.dialogs.selectTeam &&
        (this.$route.hash === "#/teams" || this.$route.hash === "#teams")
      ) {
        const newRoute = {
          ...this.$route,
          ...{
            hash: "",
          },
        };
        this.$router.replace(newRoute);
      }
    },
    routeIsPartOfGroup(group) {
      const routeParent = this.$route.name.split(".")[0];
      return group instanceof Array
        ? group.indexOf(routeParent) > -1
        : routeParent.startsWith(group);
    },
  },
};
</script>
<style lang="scss" scoped>
.active-top-left-menu {
  .v-list-item__title {
    font-weight: 700 !important;
  }
}
#top-left-menu {
  .v-list-item--link:before {
    background-color: transparent !important;
  }
}
</style>