<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions({
      doCalculateTaxRate: "billing/calculateTaxRate"
    }),
    /**
     * Determine if the given country collects European VAT.
     */
    collectsVat(country) {
      return $spark.collectsEuropeanVat
        ? _.includes(
            [
              "BE",
              "BG",
              "CZ",
              "DK",
              "DE",
              "EE",
              "IE",
              "GR",
              "ES",
              "FR",
              "HR",
              "IT",
              "CY",
              "LV",
              "LT",
              "LU",
              "HU",
              "MT",
              "NL",
              "AT",
              "PL",
              "PT",
              "RO",
              "SI",
              "SK",
              "FI",
              "SE",
              "GB"
            ],
            country
          )
        : false;
    },

    /**
     * Refresh the tax rate using the given form input.
     */
    refreshTaxRate(form) {
      // this.axios.post('/tax-rate', JSON.parse(JSON.stringify(form)))
      this.doCalculateTaxRate(form).then(response => {
        this.taxRate = response.data.rate;
      });
    },

    /**
     * Get the tax amount for the selected plan.
     */
    taxAmount(plan) {
      return plan.price * (this.taxRate / 100);
    },

    /**
     * Get the total plan price including the applicable tax.
     */
    priceWithTax(plan) {
      return plan.price + this.taxAmount(plan);
    }
  }
};
</script>
