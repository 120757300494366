<template>
  <VRow row>
    <!-- Address -->
    <VCol sm="6">
      <VLabel>Address</VLabel>
      <input
        v-model="form.address"
        type="text"
        :class="{'is-invalid': form.errors.has('address')}"
      >
      <span
        v-show="form.errors.has('address')"
        class="invalid-feedback"
      >
        {{ form.errors.get('address') }}
      </span>
    </VCol>
    <!-- Address Line 2 -->
    <VCol sm="6">
      <VLabel>Address Line 2</VLabel>
      <input
        v-model="form.address_line_2"
        type="text"
        :class="{'is-invalid': form.errors.has('address_line_2')}"
      >
      <span
        v-show="form.errors.has('address_line_2')"
        class="invalid-feedback"
      >
        {{ form.errors.get('address_line_2') }}
      </span>
    </VCol>
    <!-- City -->
    <VCol sm="6">
      <VLabel>City</VLabel>
      <input
        v-model="form.city"
        type="text"
        :class="{'is-invalid': form.errors.has('city')}"
      >
      <span
        v-show="form.errors.has('city')"
        class="invalid-feedback"
      >
        {{ form.errors.get('city') }}
      </span>
    </VCol>
    <!-- State & ZIP Code -->
    <VCol sm="3">
      <VLabel>State & ZIP / Postal Code</VLabel>
      <!-- State -->
      <input
        v-model="form.state"
        type="text"
        placeholder="State"
        :class="{'is-invalid': form.errors.has('state')}"
      >
      <span
        v-show="form.errors.has('state')"
        class="invalid-feedback"
      >
        {{ form.errors.get('state') }}
      </span>
    </VCol>
    <!-- Zip Code -->
    <VCol sm="3">
      <input
        v-model="form.zip"
        type="text"
        placeholder="Postal Code"
      >
      <span
        v-show="form.errors.has('zip')"
        class="invalid-feedback"
      >
        {{ form.errors.get('zip') }}
      </span>
    </VCol>
    <!-- Country -->
    <VCol sm="6">
      <VLabel>Country</VLabel>
      <Autocomplete
        v-model="form.country"
        :class="{'is-invalid': form.errors.has('country')}"
      />
      <span
        v-show="form.errors.has('country')"
        class="invalid-feedback"
      >
        {{ form.errors.get('country') }}
      </span>
    </VCol>
  </VRow>
</template>
<script>
import SparkForm from "@/config/spark";
export default {
  props: {
    form: {
      type: Object,
      default: () => new SparkForm()
    }
  },
  data() {
    return {};
  }
};
</script>