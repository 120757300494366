<template>
  <VCard>
    <VCardTitle>Extra Billing Information</VCardTitle>
    <VCardText>
      <!-- Information Message -->
      <VAlert
        :value="true"
        type="info"
      >
        This information will appear on all of your receipts, and is a great place to add your full business
        name, or address of record. Do not include any confidential or financial information such
        as credit card numbers.
      </VAlert>
      <!-- Success Message -->
      <VAlert
        v-if="form.successful"
        type="success"
      >
        Your billing information has been updated!
      </VAlert>
      <!-- Extra Billing Information -->
      <form role="form">
        <VRow row>
          <VCol cols="12">
            <LongTextField
              v-model="form.information"
              rows="7"
              style="font-family: monospace;"
              :class="{'is-invalid': form.errors.has('information')}"
            />
            <span
              v-show="form.errors.has('information')"
              class="invalid-feedback"
            >
              {{ form.errors.get('information') }}
            </span>
          </VCol>
        </VRow>
        <!-- Update Button -->
        <VRow
          row
          align-center
        >
          <VCol>
            <VBtn
              type="submit"
              color="primary"
              :disabled="form.busy"
              @click.prevent="update"
            >
              Update
            </VBtn>
          </VCol>
        </VRow>
      </form>
    </VCardText>
  </VCard>
</template>
<script>
import SparkForm from "@/config/spark";
import FormMixin from "@/mixins/Form";
export default {
  mixins: [FormMixin],
  /**
   * The component's data.
   */
  data() {
    return {
      form: new SparkForm({
        information: ""
      })
    };
  },

  computed: {
    /**
     * Get the URL for the extra billing information method update.
     */
    urlForUpdate() {
      return !this.$spark.usesTeams
        ? "/settings/extra-billing-information"
        : `/settings/${$spark.teamsPrefix}/${this.$team.id}/extra-billing-information`;
    }
  },

  /**
   * Prepare the component.
   */
  created() {
    this.form.information = this.billable.extra_billing_information;
  },

  methods: {
    /**
     * Update the extra billing information.
     */
    update() {
      $spark.put(this.urlForUpdate, this.form);
    }
  }
};
</script>