import BaseApi from "./_base-api";

/**
 * Teams API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	/**
	 * Check to see if a team exists
	 *
	 * @param {*} params
	 * @returns Promise
	 * @memberof Api
	 */
	teamExists (params) {
		const slug = params.slug || params;
		this.log().info("[Services: Teams]: Team Exists", slug);
		return this.api()
			.get(this.getUrl("team-exists", "team-exists", null, "api"), {
				params
			})
			.catch(error => {
				this.log().error("[Services: Teams: Team Exists]: Error", error);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * Request access to a team
	 *
	 * @param {*} params
	 * @returns Promise
	 * @memberof Api
	 */
	requestAccess (params) {
		this.log().info("[Services: Teams]: Request Access", params);
		return this.api()
			.post(
				this.getUrl("request-access", "request-access", params) +
				"/" +
				params.id,
				params
			)
			.catch(error => {
				this.log().error("[Services: Teams: Request Access]: Error", error);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * Invite a user tot he team
	 *
	 * @param {*} params
	 * @returns
	 * @memberof Api
	 */
	invite (params) {
		this.log().info("[Services: Teams]: Invite User", params);
		return this.api()
			.post(this.getUrl("team-invitations", "invitations", params), params)
			.catch(error => {
				this.log().error("[Services: Teams: Invite User]: Error", error);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * Invite users to the team with attached program
	 *
	 * @param {*} params
	 * @returns
	 * @memberof Api
	 */
	inviteToProgram (params) {
		params = params.data || params;
		this.log().info("[Services: Teams]: Invite to Program", params);
		return this.api()
			.post(this.getUrl("team-invitations", "invitations/store/many", params), params)
			.catch(error => {
				this.log().error("[Services: Teams: Invite to Progrm]: Error", error);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * Invite users to the team with attached program through CSV import
	 *
	 * @param {*} params
	 * @returns
	 * @memberof Api
	 */
	inviteUsers (params) {
		params = params.data || params;
		this.log().info("[Services: Teams]: Invite Users", params);
		return this.api()
			.post(this.getUrl("team-invitations", "invitations/import", params), params)
			.catch(error => {
				this.log().error("[Services: Teams: Invite Users]: Error", error);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * As a user, request an invite to the team
	 *
	 * @param {*} params
	 * @returns
	 * @memberof Api
	 */
	requestInvite (params) {
		this.log().info("[Services: Teams]: Request Invite", params);
		return this.api()
			.post(
				this.getUrl("request-access", "invitations/request", params),
				params
			)
			.catch(error => {
				this.log().error("[Services: Teams: Request Invite]: Error", error);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * As a user, leaving a team
	 *
	 * @param {*} team
	 * @returns
	 * @memberof Api
	 */
	leave (team) {
		this.log().info("[Services: Team]: leave team", team);
		return this.api()
			.delete(`api/teams/${team.id}/leave-team`)
			.catch(error => {
				this.log().error("[Services: Team: Leaving team]: Error", error);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * Verify an invitation
	 *
	 * @param {*} id
	 * @returns
	 * @memberof Api
	 */
	verifyInvite (id) {
		this.log().info("[Services: Teams]: Get Invite", id);
		return this.api()
			.get(this.getUrl("invitations", "show") + "/" + id)
			.catch(error => {
				this.log().error("[Services: Teams: Get Invite]: Error", error);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * Get the pending invites
	 *
	 * @param {*} slug
	 * @returns {Promise}
	 * @memberof Api
	 */
	getPendingInvites (slug) {
		this.log().info("[Services: Teams]: Get Pending Invites", slug);
		return this.api()
			.get(this.getUrl("invitations", "pending"))
			.catch(error => {
				this.log().error(
					"[Services: Teams: Getting Pending Invites]: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * As a user, get invitations
	 *
	 * @param {*} slug
	 * @returns
	 * @memberof Api
	 */
	invitations (slug) {
		this.log().info("[Services: Teams]: Get Mailed Invites", slug);
		return this.api()
			.get(this.getUrl("team-invitations", "invitations"))
			.catch(error => {
				this.log().error(
					"[Services: Teams: Getting Mailed Invites]: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * As a user, accept an invite
	 *
	 * @param {*} id
	 * @returns
	 * @memberof Api
	 */
	acceptInvite (id) {
		this.log().info("[Services: Teams]: Accept Invite", id);
		return this.api()
			.post(this.getUrl("invitations") + "/" + id + "/accept")
			.catch(error => {
				this.log().error("[Services: Teams: Accepting Invite]: Error", error);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * As a user, reject an invite
	 *
	 * @param {*} id
	 * @returns
	 * @memberof Api
	 */
	rejectInvite (id) {
		this.log().info("[Services: Teams]: Reject Invite", id);
		return this.api()
			.post(this.getUrl("invitations") + "/" + id + "/reject")
			.catch(error => {
				this.log().error("[Services: Teams: Rejecting Invite]: Error", error);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * Delete an invite
	 *
	 * @param {*} id
	 * @returns
	 * @memberof Api
	 */
	deleteInvite (id) {
		this.log().info("[Services: Teams]: Delete Invite", id);
		return this.api()
			.delete(this.getUrl("team-invtations", "invitations") + "/" + id)
			.catch(error => {
				this.log().error("[Services: Teams: Delete Invite]: Error", error);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * Approve a user for the team
	 *
	 * @param {*} id
	 * @returns
	 * @memberof Api
	 */
	approveUser (id) {
		this.log().info("[Services: Teams]: Approve User", id);
		return this.api()
			.put(this.getUrl("approve", "approve") + "/" + id)
			.catch(error => {
				this.log().error("[Services: Teams: Approve User]: Error", error);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * Delete a user from the team
	 *
	 * @param {*} id
	 * @returns
	 * @memberof Api
	 */
	deleteMember (id) {
		this.log().info("[Services: Teams]: Delete Member", id);
		return this.api()
			.delete(this.getUrl("delete-member", "delete-member") + "/" + id)
			.catch(error => {
				this.log().error("[Services: Teams: Delete Member]: Error", error);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * Set the auth token for a connect account
	 *
	 * @param {*} params
	 * @returns
	 * @memberof Api
	 */
	setAuthToken (params) {
		this.log().info("[Services: Teams]: Set Auth Token", params);
		return this.api()
			.post(
				this.getUrl("connected-accounts", "connected-accounts") +
				"/" +
				params.type,
				params
			)
			.catch(error => {
				this.log().error("[Services: Teams: Setting Auth Token]: Error", error);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * Delete a connected account
	 *
	 * @param {*} params
	 * @returns
	 * @memberof Api
	 */
	deleteConnectedAccount (params) {
		this.log().info("[Services: Teams]: Delete Connected Account", params);
		return this.api()
			.delete(
				this.getUrl("connected-accounts", "connected-accounts") + "/" + params
			)
			.catch(error => {
				this.log().error(
					"[Services: Teams: Deleting Connected Account]: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * Get the connected accounts
	 *
	 * @param {*} slug
	 * @returns
	 * @memberof Api
	 */
	getConnectedAccounts (slug) {
		this.log().info("[Services: Teams]: Get Connected Accounts", slug);
		return this.api()
			.get(this.getUrl("connected-accounts", "connected-accounts"))
			.catch(error => {
				this.log().error(
					"[Services: Teams: Getting Connected Accounts]: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * Get the connected account specified by the given slug
	 *
	 * @param {*} slug
	 * @returns
	 * @memberof Api
	 */
	getConnectedAccount (slug) {
		this.log().info("[Services: Teams]: Get Connected Account", slug);
		return this.api()
			.get(this.getUrl("connected-accounts", "connected-accounts") + "/" + slug)
			.catch(error => {
				this.log().error(
					"[Services: Teams: Getting Connected Account]: Error",
					error
				);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * Set Program's content visibility
	 *
	 * @param {*} params
	 * @returns
	 * @memberof Api
	 */
	setContentVisibility (params) {
		this.log().info("[Services: Teams]: Program's content visibility", params);
		return this.api()
			.post(this.getUrl("team", `programs/${params.programId}/content-visibility/${params.contentType}/${params.contentId}`, params), params.visibleTo)
			.catch(error => {
				this.log().error("[Services: Teams: Set Content Visibility]:", error);
				throw this.utils.resolveError(error);
			});
	}

	/**
	 * @inheritdoc
	 *
	 * @param {*} action
	 * @param {*} app
	 * @param {*} params
	 * @returns
	 * @memberof Api
	 */
	getBasePath (action, app, _params) {
		const params = _params || {};
		const realApp = app || this.$app;
		const team = realApp.$team || {};
		let basePath = "api/teams";
		switch (action) {
			case "getOne":
			case "save":
				break;

			case "invitations":
				basePath = "api/invitations";
				break;

			case "request-access":
			case "delete-member":
				if (params instanceof Object) {
					basePath =
						basePath + `/${params.team_id || params.id || team.id}`;
				} else {
					basePath = basePath + `/${team.id}`;
				}
				break;

			default:
				if (params instanceof Object) {
					basePath =
						basePath + `/${params.team_id || params.id || team.id}`;
				} else {
					basePath = basePath + `/${team.id}`;
				}
				break;
		}
		return basePath;
	}
}

const api = new Api({
	type: "Teams",
	basePath: "api/teams",
	path: {
		default: null,
		getIndexConfig: "dashboard"
	}
});

export default api;
