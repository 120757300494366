<template>
  <VCard>
    <VCardTitle>
      <div
        class="float-left"
        :class="{'btn-table-align': hasMonthlyAndYearlyPlans}"
      >
        Subscribe
      </div>
      <!-- Interval Selector Button Group -->
      <div>
        <div
          v-if="hasMonthlyAndYearlyPaidPlans"
          class="btn-group btn-group-sm"
          role="group"
        >
          <!-- Monthly Plans -->
          <VBtn
            type="light"
            :class="{'active': showingMonthlyPlans}"
            @click="showMonthlyPlans"
          >
            Monthly
          </VBtn>
          <!-- Yearly Plans -->
          <VBtn
            type="light"
            :class="{'active': showingYearlyPlans}"
            @click="showYearlyPlans"
          >
            Yearly
          </VBtn>
        </div>
      </div>
      <div class="clearfix" />
    </VCardTitle>
    <div class="table-responsive">
      <!-- European VAT Notice -->
      <template v-if="$spark.collectsEuropeanVat">
        <p class="m-4">
          All subscription plan prices are excluding applicable VAT.
        </p>
      </template>
      <!-- Plan Error Message -->
      <VAlert
        v-if="form.errors.has('plan')"
        type="error"
      >
        {{ form.errors.get('plan') }}
      </VAlert>
      <VRow
        row
        wrap
      >
        <VCol
          v-for="(plan, key) in plansForActiveInterval"
          :key="key"
          :class="{
            info: plan === selectedPlan
          }"
        >
          <VCard>
            <!-- Plan Name -->
            <VCardActions class="row justify-center">
              <VBtn
                :color="selectedPlan == plan ? 'default' : 'primary'"
                :disabled="form.busy || selectedPlan == plan"
                @click="$emit('selectPlan', plan)"
              >
                Select This Plan
              </VBtn>
            </VCardActions>
            <VCardTitle>
              <h1
                class="text-sm-center"
                style="width: 100%"
              >
                {{ plan.name }}
              </h1>
            </VCardTitle>
            <VCardText class="text-sm-center">
              <h1 class="table-plan-price">
                {{ $spark.currencySymbol }}{{ plan.price }}
              </h1>
              {{ plan.type == 'user' && $spark.chargesUsersPerSeat ? '/ '+ $spark.seatName : '' }}
              {{ plan.type == 'user' && $spark.chargesUsersPerTeam ? '/ '+ Team : '' }}
              {{ plan.type == 'team' && $spark.chargesTeamsPerSeat ? '/ '+ $spark.teamSeatName : ''
              }}
              {{ plan.type == 'team' && $spark.chargesTeamsPerMember ? '/ '+ $team.user_role_name :
                '' }}
              {{ plan.interval.capitalize() }}
            </VCardText>
            <VDivider />
            <VCardText v-if="plan.trialDays && ! hasSubscribed(plan)">
              <!-- Trial Days -->
              <h3>{{ plan.trialDays }} Day Trial</h3>
            </VCardText>
            <VDivider />
            <!-- Plan Price -->
            <VCardText>
              <VList>
                <VListItem
                  v-for="(feature, featureKey) in plan.features"
                  :key="featureKey"
                >
                  <VListItemContent>
                    <VListItemTitle class="text-sm-center">
                      {{ feature }}
                    </VListItemTitle>
                  </VListItemContent>
                </VListItem>
              </VList>
            </VCardText>
            <VDivider />
            <VCardActions class="row justify-center">
              <VBtn
                :color="selectedPlan == plan ? 'default' : 'primary'"
                :disabled="form.busy || selectedPlan == plan"
                @click="$emit('selectPlan', plan)"
              >
                {{ selectedPlan == plan ? 'Current Plan' : 'Select This Plan' }}
              </VBtn>
            </VCardActions>
          </VCard>
        </VCol>
      </VRow>
    </div>
    <!-- Plan Features Modal -->
    <Dialog
      id="modal-plan-details"
      tabindex="-1"
      :title="detailingPlan ? detailingPlan.name : ''"
      :is-dialog-open="dialogs.details"
      @toggle:dialog="dialogs.details = !dialogs.details"
    >
      <VRow
        v-if="detailingPlan"
        column
      >
        <!-- Modal Body -->
        <VCol>
          <ul class="plan-feature-list p-0 m-0">
            <li
              v-for="(feature, key) in detailingPlan.features"
              :key="key"
            >
              {{ feature }}
            </li>
          </ul>
        </VCol>
        <!-- Modal Actions -->
        <VCol>
          <VBtn
            class="default"
            @click="dialogs.details = false"
          >
            Close
          </VBtn>
        </VCol>
      </VRow>
    </Dialog>
  </VCard>
</template>
<script>
import PlansMixin from "@/mixins/Plans";
import SubscriptionMixin from "@/mixins/Subscriptions";
export default {
  /**
   * Load mixins for the component.
   */
  mixins: [PlansMixin, SubscriptionMixin],
  props: {
    plans: {
      type: Array,
      default: () => []
    },
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  }
};
</script>