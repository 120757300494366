<template>
  <VContainer
    id="user-profile"
    grid-list-lg
    style="height: 100%"
  >
    <VRow
      column
      fill-height
    >
      <VCol>
        <VTabs
          gow
          class="pt-0"
          v-model="current"
        >
          <VTab
            v-for="(item, key) in menu"
            :key="key"
            :class="{
              active: current === item.key
            }"
            @click="() => {current = item.key; drawer = false}"
          >
            <VListItemAction>
              <VIcon>{{ item.icon }}</VIcon>
            </VListItemAction>
            <VListItemContent>
              <VListItemTitle>{{ item.title }}</VListItemTitle>
            </VListItemContent>
          </VTab>
        </VTabs>
        <VTabsItems
          touchless
          v-model="current"
        >
          <VTabItem>
            <Subscription v-if="current === 0" />
          </VTabItem>
          <VTabItem>
            <PaymentMethod v-if="current === 1" />
          </VTabItem>
          <VTabItem>
            <Invoices v-if="current === 2" />
          </VTabItem>
        </VTabsItems>
      </VCol>
    </VRow>
  </VContainer>
</template>
<script>
import Subscription from "./Subscription";
import PaymentMethod from "./PaymentMethod";
import Invoices from "./Invoices";
export default {
  components: {
    Subscription,
    PaymentMethod,
    Invoices
  },
  data() {
    return {
      drawer: false,
      current: 0,
      menu: [
        {
          title: "Subscription",
          key: "subscription",
          icon: "refresh"
        },
        {
          title: "Payment Method",
          key: "payment-method",
          icon: "credit_card"
        },
        {
          title: "Invoices",
          key: "invoices",
          icon: "shopping_cart"
        }
      ]
    };
  },
  created() {
    this.loadUrl().js("https://js.stripe.com/v3");
  }
};
</script>