<template>
  <div>
    <!-- Update Extra Billing Information -->
    <ExtraInformation v-if="billable" />
    <!-- Invoice List -->
    <List />
  </div>
</template>
<script>
import ExtraInformation from "./ExtraInformation";
import List from "./List";
export default {
  components: {
    ExtraInformation,
    List
  },

  /**
   * The component's data.
   */
  data() {
    return {};
  }
};
</script>