<template>
  <div>
    <PaymentMethodStripe v-if="$spark.usesStripe" />
    <PaymentMethodBrainTree v-else />
  </div>
</template>
<script>
import PaymentMethodBrainTree from "./PaymentMethodBrainTree";
import PaymentMethodStripe from "./PaymentMethodStripe";
export default {
  components: {
    PaymentMethodBrainTree,
    PaymentMethodStripe
  },
  data() {
    return {};
  }
};
</script>