<template>
  <VCard>
    <VCardTitle>
      <!-- Redeem Button -->
      <VRow
        row
        align-center
        wrap=""
      >
        <VCol
          cols="12"
          md="6"
        >
          <span>Redeem Coupon</span>
        </VCol>
        <VCol
          cols="12"
          md="6"
        />
      </VRow>
    </VCardTitle>
    <VCardText>
      <VAlert
        v-if="form.successful"
        :value="true"
        type="success"
      >
        Coupon accepted! The discount will be applied to your next invoice.
      </VAlert>
      <VAlert
        v-if="form.error"
        :value="true"
        type="warning"
      >
        Unable to redeem this coupon: {{ form.error }}
      </VAlert>
      <form role="form">
        <!-- Coupon Code -->
        <VRow row>
          <VCol cols="12">
            <TextField
              v-model="form.coupon"
              type="text"
              name="coupon"
              label="Coupon Code"
              placeholder="Coupon Code"
              :class="{'is-invalid': form.errors.has('coupon')}"
            >
              <VBtn
                slot="append"
                type="submit"
                color="primary"
                :disabled="form.busy || !form.coupon"
                :loading="form.busy"
                @click.prevent="redeem"
                block
              >
                <span v-if="form.busy">
                  <VIcon>refresh</VIcon>Redeeming
                </span>
                <span v-else>
                  Redeem
                </span>
              </VBtn>
            </TextField>
            <span
              v-show="form.errors.has('coupon')"
              class="invalid-feedback"
            >
              {{ form.errors.get('coupon') }}
            </span>
          </VCol>
        </VRow>
      </form>
    </VCardText>
  </VCard>
</template>
<script>
import SparkForm from "@/config/spark";
import { mapActions } from "vuex";
export default {
  /**
   * The component's data.
   */
  data() {
    return {
      form: new SparkForm({
        coupon: ""
      })
    };
  },

  computed: {
    /**
     * Get the URL for redeeming a coupon.
     */
    urlForRedemption() {
      return !this.$spark.usesTeams
        ? "/settings/payment-method/coupon"
        : `/settings/${$spark.teamsPrefix}/${this.$team.id}/payment-method/coupon`;
    }
  },

  methods: {
    ...mapActions({
      doRedeemCoupon: "billing/redeemCoupon"
    }),
    /**
     * Redeem the given coupon code.
     */
    redeem() {
      // $spark.post(this.urlForRedemption, this.form)
      this.form.busy = true;
      this.form.error = false;
      this.doRedeemCoupon(this.form)
        .then(() => {
          this.form.coupon = "";
          this.form.busy = false;
          this.$parent.$emit("updateDiscount");
        })
        .catch(error => {
          this.form.busy = false;
          this.form.error = error.errors.coupon[0];
        });
    }
  }
};
</script>