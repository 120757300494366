<template>
  <VCard>
    <VCardTitle>Update VAT ID</VCardTitle>
    <VCardText>
      <!-- Success Message -->
      <VAlert
        v-if="form.successful"
        type="success"
      >
        Your VAT ID has been updated!
      </VAlert>
      <form role="form">
        <!-- VAT ID -->
        <VRow row>
          <VLabel>VAT ID</VLabel>
          <VCol md="6">
            <input
              v-model="form.vat_id"
              type="text"
              name="vat_id"
              :class="{'is-invalid': form.errors.has('vat_id')}"
            >
            <span
              v-show="form.errors.has('vat_id')"
              class="invalid-feedback"
            >
              {{ form.errors.get('vat_id') }}
            </span>
          </VCol>
        </VRow>
        <!-- Update Button -->
        <VRow row>
          <VCol
            offset-md-4
            md="6"
          >
            <VBtn
              type="submit"
              color="primary"
              :disabled="form.busy"
              @click.prevent="update"
            >
              <span v-if="form.busy">
                <VIcon>refresh</VIcon>Updating
              </span>
              <span v-else>
                Update
              </span>
            </VBtn>
          </VCol>
        </VRow>
      </form>
    </VCardText>
  </VCard>
</template>
<script>
import SparkForm from "@/config/spark";
export default {
  /**
   * The component's data.
   */
  data() {
    return {
      form: new SparkForm({
        vat_id: ""
      })
    };
  },

  computed: {
    /**
     * Get the URL for the VAT ID update.
     */
    urlForUpdate() {
      return !this.$spark.usesTeams
        ? "/settings/payment-method/vat-id"
        : `/settings/${$spark.teamsPrefix}/${this.$team.id}/payment-method/vat-id`;
    }
  },

  /**
   * Bootstrap the component.
   */
  created() {
    this.form.vat_id = this.billable.vat_id;
  },

  methods: {
    /**
     * Update the customer's VAT ID.
     */
    update() {
      $spark.put(this.urlForUpdate, this.form);
    }
  }
};
</script>