<template>
  <div>
    <VCard>
      <VCardText>
        <VBtn
          outlined
          type="danger"
          :disabled="form.busy"
          @click="confirmCancellation"
        >
          Cancel Subscription
        </VBtn>
      </VCardText>
    </VCard>
    <!-- Confirm Cancellation Modal -->
    <VDialog
      id="modal-confirm-cancellation"
      tabindex="-1"
      tile="Cancel Subscription"
      v-if="dialogs.cancel"
      width="500px"
      v-model="dialogs.cancel"
    >
      <VCard>
        <VCardTitle>Cancel Plan</VCardTitle>
        <VCardText>
          <p>Are you sure you want to cancel your subscription?</p>
        </VCardText>
        <VCardActions class="justify-end">
          <VBtn
            text
            color="default"
            data-dismiss="modal"
            :disabled="form.busy"
            @click="dialogs.cancel = false"
          >
            No, Go Back
          </VBtn>
          <VBtn
            color="error"
            :disabled="form.busy"
            :loading="form.busy"
            @click="cancel"
          >
            {{ form.busy ? 'Cancelling' : 'Yes, Cancel' }}
          </VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import SparkForm from "@/config/spark";
import { mapActions } from "vuex";
export default {
  /**
   * The component's data.
   */
  data() {
    return {
      dialogs: {
        cancel: false
      },
      form: new SparkForm({})
    };
  },

  computed: {
    /**
     * Get the URL for the subscription cancellation.
     */
    urlForCancellation() {
      return !this.$spark.usesTeams
        ? "/settings/subscription"
        : `/settings/${$spark.teamsPrefix}/${this.$team.id}/subscription`;
    }
  },

  methods: {
    ...mapActions({
      doCancel: "billing/cancelSubscription",
      doUpdateBillable: "billing/updateBillable"
    }),
    /**
     * Confirm the cancellation operation.
     */
    confirmCancellation() {
      this.dialogs.cancel = true;
    },

    /**
     * Cancel the current subscription.
     */
    cancel() {
      this.form.busy = true;
      this.doCancel(this.form)
        .then(subscriptions => {
          this.$bus.$emit("updateUser");
          this.$bus.$emit("updateTeam");
          this.dialogs.cancel = false;
          this.doUpdateBillable({ subscriptions });
        })
        .finally(() => {
          this.form.busy = false;
        });
    }
  }
};
</script>
