<template>
  <VCard>
    <VCardTitle>Invoices</VCardTitle>
    <VCardText>
      <table
        v-if="invoices.length"
        class="table"
      >
        <thead />
        <tbody>
          <tr
            v-for="(invoice, key) in invoices"
            :key="key"
          >
            <!-- Invoice Date -->
            <td>
              <strong>{{ $timezone.formatDateTime(invoice.created_at) }}</strong>
            </td>
            <!-- Invoice Total -->
            <td>{{ invoice.total }}</td>
            <!-- Invoice Download Button -->
            <td class="text-right">
              <a :href="downloadUrlFor(invoice)">
                <VBtn
                  text
                  small
                  outlined
                  color="primary"
                >
                  <VIcon>cloud_download</VIcon>Download PDF
                </VBtn>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>
        No invoices yet
      </p>
    </VCardText>
  </VCard>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "InvoiceLsit",

  /**
   * The component's data.
   */
  data() {
    return {
      invoices: []
    };
  },

  /**
   * Prepare the component.
   */
  mounted() {
    this.getInvoices();
  },
  methods: {
    ...mapActions({
      doGetInvoices: "billing/getInvoices"
    }),
    /**
     * Get the URL for downloading a given invoice.
     */
    downloadUrlFor(invoice) {
      return this.$spark.billingUser
        ? `/api/billing/invoice/${invoice.id}`
        : `/api/billing/${this.$spark.teamsPrefix}/${this.$team.id}/invoice/${invoice.id}`;
    },
    /**
     * Get the user's billing invoices
     */
    getInvoices() {
      this.doGetInvoices().then(invoices => {
        this.invoices = _.filter(invoices, invoice => {
          return invoice.total != "$0.00";
        });
      });
    }
  }
};
</script>