<template>
  <div>
    <!-- Current Discount -->
    <VAlert
      v-if="currentDiscount"
      type="success"
      :value="true"
    >
      <span
        v-if="currentDiscount.duration=='repeating' && currentDiscount.duration_in_months > 1"
      >
        You currently receive a discount of {{ formattedDiscount(currentDiscount) }} for all invoices during the next {{ currentDiscount.duration_in_months }} billing cycles.
      </span>
      <span
        v-if="currentDiscount.duration=='repeating' && currentDiscount.duration_in_months == 1"
      >
        You currently receive a discount of {{ formattedDiscount(currentDiscount) }} for all invoices during the next billing cycle.
      </span>
      <span
        v-if="currentDiscount.duration=='forever'"
      >
        You currently receive a discount of {{ formattedDiscount(currentDiscount) }} forever.
      </span>
      <span
        v-if="currentDiscount.duration=='once'"
      >
        You currently receive a discount of {{ formattedDiscount(currentDiscount) }} for a single invoice.
      </span>
    </VAlert>
    <!-- Update VAT ID -->
    <template v-if="$spark.collectsEuropeanVat">
      <UpdateVatID />
    </template>
    <!-- Update Card -->
    <UpdatePaymentMethodStripe />
    <!-- Redeem Coupon -->
    <RedeemCoupon v-if="billable.stripe_id" />
  </div>
</template>
<script>
import RedeemCoupon from "./RedeemCoupon";
import UpdatePaymentAddress from "./UpdatePaymentAddress";
import UpdatePaymentMethodStripe from "./UpdatePaymentMethodStripe";
import UpdateVatID from "./UpdateVatID";
import DiscountsMixin from "@/mixins/Discounts";

export default {
  components: {
    UpdatePaymentMethodStripe,
    RedeemCoupon
  },
  /**
   * Load mixins for the component.
   */
  mixins: [DiscountsMixin],

  /**
   * The componetn's data.
   */
  data() {
    return {
      currentDiscount: null,
      loadingCurrentDiscount: false
    };
  },

  /**
   * The component has been created by Vue.
   */
  created() {
    this.$on("updateDiscount", function() {
      this.getCurrentDiscountForBillable(this.billableType, this.billable);

      return true;
    });
  },

  /**
   * Prepare the component.
   */
  mounted() {
    this.getCurrentDiscountForBillable(this.billableType, this.billable);
  }
};
</script>
