<template>
  <VCard
    min-height="240px"
    style="overflow-y: auto;"
  >
    <VCardTitle
      class="pt-5 pb-3"
      style="border: none;"
    >
      <VRow>
        <VCol
          lg="8"
          md="12"
          sm="12"
          class="d-flex align-center"
        >
          <span class="label-text">Huddles</span>
          <VTooltip top>
            <template #activator="{ on: tooltipOn }">
              <VIcon v-on="tooltipOn">
                info
              </VIcon>
            </template>
            <span>This is where all of the chat messages for {{ model.title }} will appear.</span>
          </VTooltip>
        </VCol>
        <VCol
          v-if="$isUserAdmin && $viewProgramAsAdmin"
          lg="4"
          md="12"
          sm="12"
          class="d-flex justify-end"
        >
          <VTooltip top>
            <template #activator="{ on }">
              <VBtn
                v-on="on"
                fab
                width="40px"
                min-width="40px"
                height="40px"
                class="wethrive"
                style="border-radius: 10px;"
                :block="$vuetify.breakpoint.md"
                :disabled="model.mentors.length === 0 && model.students.length === 0"
                @click="createNewChat"
              >
                <VIcon
                  color="white"
                  size="30px"
                >
                  mdi-plus
                </VIcon>
              </VBtn>
            </template>
            Add Huddle
          </VTooltip>
        </VCol>
      </VRow>
    </VCardTitle>
    <VCardText
      class="pa-0"
      style="max-height: 550px; overflow-y: auto;"
    >
      <VList v-if="threads.length > 0">
        <VListItem
          v-for="(thread, index) in threads"
          three-line
          style="box-shadow: 0 0.5px 0 0 #ececf1;"
          :key="index"
        >
          <VListItemContent
            :style="{
              cursor: hasThreadMember(thread) ? 'pointer' : 'default',
            }"
            @click="openChatThread(thread)"
          >
            <VListItemTitle style="line-height: 1.6;">
              <VRow>
                <VCol
                  cols="9"
                  class="px-0"
                >
                  <BetaBadge 
                    is-beta
                    offset-x="-1"
                    :color="thread.is_private ? 'secondary' : 'success'"
                    :label="thread.is_private ? 'Private' : 'Public'"
                  >
                    <span
                      class="text-wrap"
                      style="font-size: 16px; font-weight: 900;"
                    >
                      {{ thread.title }}
                    </span>
                  </BetaBadge>
                </VCol>
                <VCol
                  v-if="thread.new_messages_for_user_count > 0"
                  cols="3"
                  class="px-0 text-right"
                >
                  <VAvatar
                    size="22px"
                    color="#ff2a7a"
                  >
                    <span
                      class="white--text"
                      style="font-size: 14px;"
                    >
                      {{ thread.new_messages_for_user_count }}
                    </span>
                  </VAvatar>
                </VCol>
              </VRow>
            </VListItemTitle>
            <VListItemSubtitle
              v-if="hasThreadMember(thread) && thread.last_message"
              style="font-size: 14px; color: #808080; line-height: 1.4;"
            >
              <b>{{ thread.last_message.user?.name }}:</b> {{ thread.last_message?.message }}
            </VListItemSubtitle>
            <VListItemActionText class="mx-0 mb-0 mt-4 d-flex">
              <AvatarGroup
                size="28px"
                color="#fff3d0"
                text-color="#fdbf14"
                tooltip-text="Members"
                :custom-style="{
                  cursor: hasThreadMember(thread) ? 'pointer' : 'default',
                }"
                :preview-users="thread.group?.members"
                :total-users="thread.group_members_count"
                @showDetails="hasThreadMember(thread) ? onSetViewDialogModel(thread) : ''"
              />
              <VSpacer />
              <VBtn
                v-if="$isUserAdmin && $viewProgramAsAdmin"
                icon
                :loading="thread.isLoading"
                @click.stop="onDeleteThread(index, thread)"
              >
                <VIcon color="error">
                  delete
                </VIcon>
              </VBtn>
              <VTooltip top>
                <template #activator="{on}">
                  <VBtn
                    v-if="!hasThreadMember(thread)"
                    v-on="on"
                    color="success"
                    height="28px"
                    min-width="44px"
                    class="px-1"
                    :loading="thread.isLoading"
                    @click.stop="joinThread(index, thread)"
                  >
                    Join
                  </VBtn>
                </template>
                Join this Huddle
              </VTooltip>
            </VListItemActionText>
          </VListItemContent>
        </VListItem>
      </VList>
    </VCardText>
    <StartChatDialog
      for-huddle
      hide-filters
      dialog-hide-overlay
      ref="startChatDialog"
      max-width="650px"
      min-height="550px"
      entity-type="program"
      :entity="model"
      :thread-editable="$isUserAdmin"
      :members="(model.mentors).concat(model.students)"
      :is-dialog-open="dialog"
      :custom-filter="{ filter: { program: [model.id] } }"
      @toggle:dialog="dialog = !dialog"
      @newThread="thread => model.threads.push(thread)"
      @updateThread="onUpdateThread"
    />
    <Dialog
      max-width="640px"
      min-height="350px"
      title="Group Members"
      :is-dialog-open="dialogs.view"
      @toggle:dialog="onToggleViewDialog"
    >
      <template v-if="currentModel">
        <UserModal
          v-for="(people, index) in currentModel.group.members"
          verticle-view
          class="px-2 py-6"
          style="display: inline-grid;"
          :key="index"
          :user="people"
        />
      </template>
    </Dialog>
    <Confirm ref="confirmDelete" />
    <Confirm
      ref="confirmHuddleJoin"
      confirm-color="info"
      cancel-color="disabled"
    />
  </VCard>
</template>
<script>
import { mapActions } from "vuex";
import ViewProgramSection from "@/mixins/ViewProgramSection";
import DialogViewMixin from "@/mixins/DialogView";
import DeletesItems from "@/mixins/DeletesItems";
import AvatarGroup from "@/components/Elements/Data/AvatarGroup";
import StartChatDialog from "@/components/Chat/StartChatDialog";
export default {
  name: "Conversations",
  mixins: [ViewProgramSection, DialogViewMixin, DeletesItems],
  components: {
    AvatarGroup,
    StartChatDialog,
  },
  data () {
    return {
      dialog: false,
    };
  },
  computed: {
    threads () {
      return this.model?.threads || [];
    },
  },
  methods: {
    ...mapActions({
      doJoinPublicThread: "chat/joinPublicThread",
    }),
    openChatThread (thread) {
      if(this.hasThreadMember(thread)) {
        this.$refs.startChatDialog.onSetCurrentThread(thread);
        this.dialog = true;
      }
    },
    createNewChat () {
      this.$refs.startChatDialog.onToggleMode("creating");
      this.dialog = true;
    },
    onDelete (thread) {
      return this.$refs.startChatDialog.onDeleteThread(thread);
    },
    onDeleteThread (index, thread) {
      this.onDeleteItem(thread, null, null, false).then((result) => {
        if (result) {
          this.model.threads.splice(index, 1);
          this.$bus.$emit(
            "notificationMessage",
            `Successfully huddle deleted`
          );
        }
      });
    },
    hasThreadMember(thread) {
      if(!this.$isUserAdmin && !thread.is_private) {
        let memberIndex = thread.group.members.findIndex((member) => member.id === this.$user.id);
        return memberIndex > -1;
      }
      return true;
    },
    joinThread(index, thread) {
      this.$refs
        .confirmHuddleJoin
        .open("Join Huddle", `Are you sure you want to join ${thread.title}?`, {
          color: "info",
        })
        .then((status) => {
          if(status) {
            this.$set(this.threads[index], "isLoading", true);
            this.doJoinPublicThread(thread).then((result) => {
              this.$set(this.threads, index, result);
              this.$bus.$emit(
                "notificationMessage",
                "Joined huddle successfully"
              );
            });
          }
        });
    },
    onUpdateThread(thread) {
      const threadIndex = this.threads.findIndex((t) => t.id === thread.id);
      this.$set(this.threads, threadIndex, thread);
    },
  }
}
</script>