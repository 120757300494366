<template>
  <AppLayout>
    <VContainer
      slot="main-content"
      class="billing-index"
      grid-list-lg
    >
      <Billing />
    </VContainer>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import Billing from "@/components/Account/Billing";

export default {
  name: "BillingIndex",
  components: {
    Billing,
    AppLayout
  },
  data() {
    return {};
  }
};
</script>

<style>
.user-profile {
  height: 100%;
}
</style>