<template>
  <div>
    <!-- Common Subscribe Form Contents -->
    <SubscribeCommon
      :form="form"
      :plans="plans"
      @selectPlan="(plan) => selectPlan(plan)"
    />
    <!-- Billing Information -->
    <VCard v-show="selectedPlan">
      <VCardTitle>Billing Information</VCardTitle>
      <VCardText>
        <!-- Generic 500 Level Error Message / Stripe Threw Exception -->
        <VAlert
          v-if="form.errors.has('form')"
          :value="true"
          type="warning"
        >
          We had trouble validating your card. It's possible your card provider is preventing us from
          charging the card. Please contact your card provider or customer support.
        </VAlert>
        <form role="form">
          <!-- Payment Method -->
          <VRow
            v-if="hasPaymentMethod"
            row
          >
            <VCol cols="12">
              <VSelect
                id="use_existing_payment_method"
                v-model="form.use_existing_payment_method"
                name="use_existing_payment_method"
                label="Payment Method"
                placeholder="Select Payment Method"
                :items="[{value: 0, text: 'Use a different method'}, {value: 1, text: 'Use existing payment method'}]"
              />
            </VCol>
          </VRow>
          <!-- Cardholder's Name -->
          <VRow
            v-show="!form.use_existing_payment_method"
            row
          >
            <VCol cols="12">
              <TextField
                v-model="cardForm.name"
                type="text"
                name="name"
                placeholder="Cardholder's Name"
                label="Cardholder's Name"
              />
            </VCol>
          </VRow>
          <!-- Card Details -->
          <VRow
            v-show="!form.use_existing_payment_method"
            row
          >
            <VCol cols="12">
              <div id="subscription-card-element" />
              <span
                v-if="cardForm.errors.has('card')"
                class="invalid-feedback"
              >
                {{ cardForm.errors.get('card') }}
              </span>
            </VCol>
          </VRow>
          <!-- Billing Address Fields -->
          <template v-if="$spark.collectsBillingAddress">
            <SubscribeAddress />
          </template>
          <!-- ZIP Code -->
          <VRow
            v-if="!$spark.collectsBillingAddress"
            row
          >
            <VCol cols="12">
              <TextField
                v-model="form.zip"
                type="text"
                name="zip"
                placeholder="ZIP / Postal Code"
                label="ZIP / Postal Code"
              />
            </VCol>
          </VRow>
          <!-- Coupon -->
          <VRow row>
            <VCol cols="12">
              <TextField
                v-model="form.coupon"
                type="text"
                :class="{'is-invalid': form.errors.has('coupon')}"
                placeholder="Coupon"
                label="Coupon"
              />
              <span
                v-show="form.errors.has('coupon')"
                class="invalid-feedback"
              >
                {{ form.errors.get('coupon') }}
              </span>
            </VCol>
          </VRow>
          <!-- Tax / Price Information -->
          <VRow
            v-if="$spark.collectsEuropeanVat && countryCollectsVat && selectedPlan"
            row
          >
            <VLabel>&nbsp;</VLabel>
            <VCol cols="12">
              <VAlert
                type="info"
                style="margin: 0;"
              >
                <strong>Tax:</strong>
                {{ taxAmount(selectedPlan) | currency }}
                <br>
                <br>
                <strong>Total Price Including Tax:</strong>
                {{ priceWithTax(selectedPlan) | currency }}
                {{ selectedPlan.type == 'user' && $spark.chargesUsersPerSeat ? '/ '+ $spark.seatName :
                  '' }}
                {{ selectedPlan.type == 'user' && $spark.chargesUsersPerTeam ? '/ '+ 'Team' : '' }}
                {{ selectedPlan.type == 'team' && $spark.chargesTeamsPerSeat ? '/ '+
                  $spark.teamSeatName : '' }}
                {{ selectedPlan.type == 'team' && $spark.chargesTeamsPerMember ? '/ '+
                  $team.user_role_name : '' }}
                / {{ selectedPlan.interval.capitalize() }}
              </VAlert>
            </VCol>
          </VRow>
          <!-- Subscribe Button -->
          <VRow row>
            <VCol cols="12">
              <VBtn
                type="submit"
                color="primary"
                :disabled="form.busy"
                :loading="form.busy"
                @click.prevent="subscribe"
                block
              >
                <span v-if="form.busy">
                  Subscribing
                </span>
                <span v-else>
                  Subscribe
                </span>
              </VBtn>
            </VCol>
          </VRow>
        </form>
      </VCardText>
    </VCard>
  </div>
</template>
<script>
import SparkForm from "@/config/spark";
import PlansMixin from "@/mixins/Plans";
import SubscriptionMixin from "@/mixins/Subscriptions";
import StripeMixin from "@/mixins/Stripe";
import VatMixin from "@/mixins/Vat";
import SubscribeCommon from "./SubscribeCommon";
import { mapActions } from "vuex";

export default {
  /**
   * Components
   */
  components: {
    SubscribeCommon
  },

  /**
   * Load mixins for the component.
   */
  mixins: [PlansMixin, SubscriptionMixin, VatMixin, StripeMixin],
  props: {
    plans: {
      type: Array,
      default: () => []
    }
  },

  /**
   * The component's data.
   */
  data() {
    return {
      taxRate: 0,
      cardElement: null,
      form: new SparkForm({
        use_existing_payment_method: "0",
        stripe_token: "",
        plan: "",
        coupon: null,
        address: "",
        address_line_2: "",
        city: "",
        state: "",
        zip: "",
        country: "US",
        vat_id: ""
      }),

      cardForm: new SparkForm({
        name: ""
      })
    };
  },

  computed: {
    /**
     * Get the billable entity's "billable" name.
     */
    billableName() {
      return !this.$spark.usesTeams ? this.$user.name : this.$team.owner.name;
    },

    /**
     * Determine if the selected country collects European VAT.
     */
    countryCollectsVat() {
      return this.collectsVat(this.form.country);
    },

    /**
     * Get the URL for subscribing to a plan.
     */
    urlForNewSubscription() {
      return !this.$spark.usesTeams
        ? "/settings/subscription"
        : `/settings/${$spark.teamsPrefix}/${this.$team.id}/subscription`;
    },

    /**
     * Get the current billing address from the subscribe form.
     *
     * This used primarily for wathcing.
     */
    currentBillingAddress() {
      return (
        this.form.address +
        this.form.address_line_2 +
        this.form.city +
        this.form.state +
        this.form.zip +
        this.form.country +
        this.form.vat_id
      );
    },

    /**
     * Determine if the user/team has a payment method defined.
     */
    hasPaymentMethod() {
      return this.billable.card_last_four;
    }
  },

  watch: {
    /**
     * Watch for changes on the entire billing address.
     */
    currentBillingAddress: function(value) {
      if (!$spark.collectsEuropeanVat) {
        return;
      }

      this.refreshTaxRate(this.form);
    }
  },

  /**
   * Prepare the component.
   */
  mounted() {
    this.form.use_existing_payment_method = this.hasPaymentMethod ? 1 : 0;
    this.cardElement = this.createCardElement("#subscription-card-element");

    this.initializeBillingAddress();

    if (this.onlyHasYearlyPaidPlans) {
      this.showYearlyPlans();
    }
  },

  methods: {
    ...mapActions({
      doSubscribe: "billing/createSubscription"
    }),
    /**
     * Initialize the billing address form for the billable entity.
     */
    initializeBillingAddress() {
      this.form.address = this.billable.billing_address;
      this.form.address_line_2 = this.billable.billing_address_line_2;
      this.form.city = this.billable.billing_city;
      this.form.state = this.billable.billing_state;
      this.form.zip = this.billable.billing_zip;
      this.form.country = this.billable.billing_country || "US";
      this.form.vat_id = this.billable.vat_id;
    },

    /**
     * Mark the given plan as selected.
     */
    selectPlan(plan) {
      this.selectedPlan = plan;

      this.form.plan = this.selectedPlan.id;
    },

    /**
     * Subscribe to the specified plan.
     */
    subscribe() {
      this.cardForm.errors.forget();

      this.form.startProcessing();

      if (this.form.use_existing_payment_method) {
        this.createSubscription();
        return;
      }

      // Here we will build out the payload to send to Stripe to obtain a card token so
      // we can create the actual subscription. We will build out this data that has
      // this credit card number, CVC, etc. and exchange it for a secure token ID.
      const payload = {
        name: this.cardForm.name,
        address_line1: this.form.address || "",
        address_line2: this.form.address_line_2 || "",
        address_city: this.form.city || "",
        address_state: this.form.state || "",
        address_zip: this.form.zip || "",
        address_country: this.form.country || ""
      };

      // Next, we will send the payload to Stripe and handle the response. If we have a
      // valid token we can send that to the server and use the token to create this
      // subscription on the back-end. Otherwise, we will show the error messages.
      this.stripe.createToken(this.cardElement, payload).then(response => {
        if (response.error) {
          this.cardForm.errors.set({
            card: [response.error.message]
          });

          this.form.busy = false;
        } else {
          this.createSubscription(response.token.id).then(result => {
            this.form.finishProcesssing();
          });
        }
      });
    },

    /*
     * After obtaining the Stripe token, create subscription on the Spark server.
     */
    createSubscription(token) {
      this.form.stripe_token = token;

      return this.doSubscribe(this.form)
        .then(plans => {
          this.$bus.$emit("updateUser");
          this.$bus.$emit("updateTeam");
        })
        .catch(response => {
          this.form.setErrors(true);
        });
    },

    /**
     * Determine if the user has subscribed to the given plan before.
     */
    hasSubscribed(plan) {
      return !!_.filter(this.billable.subscriptions, {
        provider_plan: plan.id
      }).length;
    },

    /**
     * Show the plan details for the given plan.
     *
     * We'll ask the parent subscription component to display it.
     */
    showPlanDetails(plan) {
      this.$parent.$emit("showPlanDetails", plan);
    }
  }
};
</script>