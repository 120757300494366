<template>
  <SubscribeStripe :plans="plans" />
</template>
<script>
import SubscribeStripe from "./SubscribeStripe";
export default {
  props: {
    plans: {
      type: Array,
      default: () => []
    }
  },
  components: {
    SubscribeStripe
  },
  data() {
    return {};
  }
};
</script>