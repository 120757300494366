<template>
  <div>
    <div v-if="plans.length > 0">
      <!-- Trial Expiration Notice -->
      <VAlert
        v-if="subscriptionIsOnTrial"
        type="warning"
        :value="true"
      >
        {{ `You are currently within your free trial period. Your trial will expire on ${trialEndsAt}.` }}
      </VAlert>
      <!-- New Subscription -->
      <div v-if="needsSubscription">
        <Subscribe :plans="plans" />
      </div>
      <!-- Update Subscription -->
      <div v-if="subscriptionIsActive">
        <UpdateSubscription :plans="plans" />
      </div>
      <!-- Resume Subscription -->
      <div v-if="subscriptionIsOnGracePeriod">
        <ResumeSubscription :plans="plans" />
      </div>
      <!-- Cancel Subscription -->
      <div v-if="subscriptionIsActive">
        <CancelSubscription :plans="plans" />
      </div>
    </div>
    <VAlert 
      v-else 
      type="info"
    >
      Waiting for plans...
    </VAlert>
  </div>
</template>
<script>
import ResumeSubscription from "./ResumeSubscription";
import CancelSubscription from "./CancelSubscription";
import UpdateSubscription from "./UpdateSubscription";
import Subscribe from "./Subscribe";
import PlansMixin from "@/mixins/Plans";
import SubscriptionMixin from "@/mixins/Subscriptions";
import { mapActions } from "vuex";

export default {
  components: {
    ResumeSubscription,
    CancelSubscription,
    UpdateSubscription,
    Subscribe
    // PlanDetails
  },
  /**
   * Load mixins for the component.
   */
  mixins: [PlansMixin, SubscriptionMixin],

  /**
   * The component's data.
   */
  data() {
    return {
      plans: []
    };
  },

  /**
   * Prepare the component.
   */
  created() {
    this.getPlans();

    this.$on("showPlanDetails", plan => {
      this.showPlanDetails(plan);
    });
  },

  methods: {
    ...mapActions({
      doGetPlans: "billing/getPlans"
    }),
    /**
     * Get the active plans for the application.
     */
    getPlans() {
      // this.axios.get('/spark/plans')
      this.doGetPlans().then(plans => {
        this.plans = !this.$spark.usesTeams
          ? plans.filter(plan => plan.type == "user")
          : plans.filter(plan => plan.type == "team");
      });
    }
  }
};
</script>