<template>
  <VCard>
    <!-- Update Payment Method Heading -->
    <VCardTitle>
      <VRow
        row
        align-center
        wrap
      >
        <VCol
          cols="12"
          md="6"
        >
          Update Payment Method
        </VCol>
        <VCol
          cols="12"
          md="6"
          class="text-sm-left text-md-right"
        >
          <span v-if="billable.card_last_four">
            <FontAwesomeIcon
              style="font-size: 2rem"
              :icon="['fab', cardIcon]"
            />
            ************{{ billable.card_last_four }}
          </span>
        </VCol>
      </VRow>
    </VCardTitle>
    <VCardText>
      <!-- Card Update Success Message -->
      <VAlert
        v-if="form.successful"
        type="success"
      >
        Your card has been updated.
      </VAlert>
      <!-- Generic 500 Level Error Message / Stripe Threw Exception -->
      <VAlert
        v-if="form.errors.has('form')"
        type="error"
      >
        We had trouble updating your card. It's possible your card provider is preventing us from charging the
        card. Please contact your card provider or customer support.
      </VAlert>
      <form role="form">
        <!-- Cardholder's Name -->
        <VRow row>
          <VCol cols="12">
            <TextField
              v-model="cardForm.name"
              type="text"
              placeholder="Cardholder's Name"
              label="Cardholder's Name"
            />
          </VCol>
        </VRow>
        <!-- Card Details -->
        <VRow row>
          <VCol cols="12">
            <div id="payment-card-element" />
            <VDivider style="margin-top: 10px" />
            <span
              v-show="cardForm.errors.has('card')"
              class="invalid-feedback"
            >
              {{ cardForm.errors.get('card') }}
            </span>
          </VCol>
        </VRow>
        <!-- Billing Address Fields -->
        <template v-if="$spark.collectsBillingAddress">
          <UpdatePaymentAddress v-model="form" />
        </template>
        <!-- Zip Code -->
        <VRow
          v-if="! $spark.collectsBillingAddress"
          row
        >
          <VCol cols="12">
            <TextField
              v-model="form.zip"
              type="text"
              placeholder="ZIP / Postal Code"
              label="ZIP / Postal Code"
            />
          </VCol>
        </VRow>
        <!-- Update Button -->
        <VRow row>
          <VCol>
            <VBtn
              type="submit"
              color="primary"
              :disabled="form.busy"
              @click.prevent="update"
              block
            >
              <span v-if="form.busy">
                <VIcon>refresh</VIcon>Updating
              </span>
              <span v-else>
                Update
              </span>
            </VBtn>
          </VCol>
        </VRow>
      </form>
    </VCardText>
  </VCard>
</template>
<script>
import SparkForm from "@/config/spark";
import StripeMixin from "@/mixins/Stripe";
import UpdatePaymentAddress from "./UpdatePaymentAddress";
import { mapActions, mapState } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  components: {
    UpdatePaymentAddress,
    FontAwesomeIcon
  },
  /**
   * Load mixins for the component.
   */
  mixins: [StripeMixin],

  /**
   * The component's data.
   */
  data() {
    return {
      cardElement: null,
      form: new SparkForm({
        stripe_token: "",
        address: "",
        address_line_2: "",
        city: "",
        state: "",
        zip: "",
        country: "US"
      }),

      cardForm: new SparkForm({
        name: ""
      })
    };
  },

  computed: {
    /**
     * Get the billable entity's "billable" name.
     */
    billableName() {
      return !this.$spark.usesTeams ? this.$user.name : this.$team.owner.name;
    },

    /**
     * Get the URL for the payment method update.
     */
    urlForUpdate() {
      return !this.$spark.usesTeams
        ? "/settings/payment-method"
        : `/settings/${this.$spark.teamsPrefix}/${this.$team.id}/payment-method`;
    },

    /**
     * Get the proper brand icon for the customer's credit card.
     */
    cardIcon() {
      if (!this.billable.card_brand) {
        return "cc-stripe";
      }

      switch (this.billable.card_brand) {
        case "American Express":
          return "cc-amex";
        case "Diners Club":
          return "cc-diners-club";
        case "Discover":
          return "cc-discover";
        case "JCB":
          return "cc-jcb";
        case "MasterCard":
          return "cc-mastercard";
        case "Visa":
          return "cc-visa";
        default:
          return "cc-stripe";
      }
    },

    /**
     * Get the placeholder for the billable entity's credit card.
     */
    placeholder() {
      if (this.billable.card_last_four) {
        return `************${this.billable.card_last_four}`;
      }

      return "";
    }
  },

  /**
   * Prepare the component.
   */
  mounted() {
    this.cardElement = this.createCardElement("#payment-card-element");

    this.initializeBillingAddress();
  },

  methods: {
    ...mapActions({
      doUpdatePaymentMethod: "billing/updatePaymentMethod"
    }),
    /**
     * Initialize the billing address form for the billable entity.
     */
    initializeBillingAddress() {
      if (!this.$spark.collectsBillingAddress) {
        return;
      }

      this.form.address = this.billable.billing_address;
      this.form.address_line_2 = this.billable.billing_address_line_2;
      this.form.city = this.billable.billing_city;
      this.form.state = this.billable.billing_state;
      this.form.zip = this.billable.billing_zip;
      this.form.country = this.billable.billing_country || "US";
    },

    /**
     * Update the billable's card information.
     */
    update() {
      this.form.busy = true;
      this.form.errors.forget();
      this.form.successful = false;
      this.cardForm.errors.forget();

      // Here we will build out the payload to send to Stripe to obtain a card token so
      // we can create the actual subscription. We will build out this data that has
      // this credit card number, CVC, etc. and exchange it for a secure token ID.
      const payload = {
        name: this.cardForm.name,
        address_line1: this.form.address || "",
        address_line2: this.form.address_line_2 || "",
        address_city: this.form.city || "",
        address_state: this.form.state || "",
        address_zip: this.form.zip || "",
        address_country: this.form.country || ""
      };

      // Once we have the Stripe payload we'll send it off to Stripe and obtain a token
      // which we will send to the server to update this payment method. If there is
      // an error we will display that back out to the user for their information.
      this.stripe.createToken(this.cardElement, payload).then(response => {
        if (response.error) {
          this.cardForm.errors.set({
            card: [response.error.message]
          });

          this.form.busy = false;
        } else {
          this.sendUpdateToServer(response.token.id);
          this.form.busy = false;
        }
      });
    },

    /**
     * Send the credit card update information to the server.
     */
    sendUpdateToServer(token) {
      this.form.stripe_token = token;

      this.doUpdatePaymentMethod(this.form).then(lastFour => {
        this.$bus.$emit("updateUser");
        this.$bus.$emit("updateTeam");

        this.cardForm.name = "";
        this.cardForm.number = "";
        this.cardForm.cvc = "";
        this.cardForm.month = "";
        this.cardForm.year = "";

        if (!this.$spark.collectsBillingAddress) {
          this.form.zip = "";
        }
        this.billable.card_last_four = lastFour;
      });
    }
  }
};
</script>