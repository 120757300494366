<template>
  <VContainer
    v-if="mode === 'standalone'"
    fluid
    :class="{
      'save-header': true,
      'col': col
    }"
  >
    <VRow
      row
      align-center
      class="mr-2"
      :wrap="$vuetify.breakpoint.smAndDown"
    >
      <VCol
        :shrink="$vuetify.breakpoint.mdAndUp && hasContent"
        :grow="$vuetify.breakpoint.mdAndUp && !hasContent"
        :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'"
        :class="{
          xs12: $vuetify.breakpoint.smAndDown
        }"
      >
        <VRow
          row
          align-center
          justify-start
          nowrap
        >
          <VCol
            v-if="!hideTitleWithBack && !hideBackButton"
            style="flex: 0 0 48px"
          >
            <VBtn
              icon
              large
              @click="onGoBack"
            >
              <img
                v-if="altBackIcon"
                src="../../../images/forms/arrow-icon.png"
              >
              <VIcon v-else>
                arrow_back
              </VIcon>
            </VBtn>
          </VCol>
          <VCol v-if="!hideTitleWithBack && !hideBackButton">
            <h1
              :class="{
                'pr-8': isBetaFeature(owner.modelType),
                'text-truncate text-no-wrap title': true,
                'text-center': $vuetify.breakpoint.smAndDown
              }"
              :style="{
                whiteSpace: $vuetify.breakpoint.smAndDown ? 'normal' : 'normal',
              }"
            >
              <slot name="title">
                {{ owner.formAction }} {{ featureName(owner.modelType).capitalize() }}
              </slot>
              <BetaBadge
                enable-tooltip
                :is-beta="isBetaFeature(owner.modelType)"
              />
            </h1>
          </VCol>
          <VCol style="flex: 0 0 48px">
            <slot name="title-button" />
          </VCol>
        </VRow>
      </VCol>
      <VCol
        v-if="hasContent"
        align-center
        justify-start
        grow
        :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'"
        :class="{
          xs12: $vuetify.breakpoint.smAndDown,
          'order-sm3': true,
          'order-md2': true,
          'mr-2': true
        }"
      >
        <slot name="content-center" />
      </VCol>
      <VCol
        :pr-0="$vuetify.breakpoint.smAndDown"
        :shrink="$vuetify.breakpoint.mdAndUp && hasContent"
        :grow="$vuetify.breakpoint.mdAndUp && !hasContent"
        :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'"
        :class="{
          'order-sm2': true,
          'order-md3': true
        }"
      >
        <VRow
          row
          align-center
          nowrap
          :justify-end="$vuetify.breakpoint.mdAndUp"
        >
          <VCol
            shrink
            px-0
            :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'"
          >
            <VRow
              row
              align-center
            >
              <slot name="actions-left" />
            </VRow>
          </VCol>
          <VCol
            v-if="!disableDefaultActions"
            grow
            pr-0
            :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'"
          >
            <VRow
              row
              nowrap
              align-center
              :class="{
                'px-6': $vuetify.breakpoint.smAndDown
              }"
              :justify-end="$vuetify.breakpoint.mdAndUp"
              :justify-space-between="$vuetify.breakpoint.smAndDown"
            >
              <slot name="actions-center-left" />
              <slot name="actions-center">
                <VCol class="d-flex justify-end wrap">
                  <ExpandableButton
                    v-if="!hideTitleWithBack"
                    :icon-button="iconButton"
                    :icon="saveBtnIcon"
                    :text="saveBtnText"
                    :block="$vuetify.breakpoint.smAndDown"
                    :disabled="!owner.isFormReady || owner.isFormInputsDisabled"
                    :loading="owner.activity.isFormLoading"
                    :action="() => owner.onSave()"
                  />
                  <ExpandableButton
                    v-if="!disableAddNewText && owner.wasCreateMode && owner.form.id"
                    icon="add"
                    :button-class="{
                      'wethrive-alt': true,
                      'ml-2': true
                    }"
                    :block="$vuetify.breakpoint.smAndDown"
                    :disabled="!owner.isFormReady || owner.isFormInputsDisabled"
                    :action="onSaveAndReset"
                    :text="addNewText"
                    :tooltip-text="addNewText"
                    :show-text="$vuetify.breakpoint.smAndUp || $vuetify.breakpoint.smAndDown"
                  />
                </VCol>
              </slot>
              <slot name="actions-center-right" />
            </VRow>
          </VCol>
          <VCol
            shrink
            px-0
            :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'"
          >
            <VRow
              row
              align-center
            >
              <slot name="actions-right" />
            </VRow>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <VRow>
      <VCol
        v-if="owner.hasMessagesOrErrors"
        :class="{
          'mb-2 mt-3': true,
          'mt-2': hideTitleWithBack
        }"
      >
        <Alerts
          :messages="owner.messages"
          :errors="owner.errors"
          :align-icon="alignAlertIcon"
        >
          <template #alert-message-action>
            <slot name="response-action" />
          </template>
        </Alerts>
      </VCol>
    </VRow>
  </VContainer>
</template>
<script>
import SupportsModelTypes from "@/mixins/SupportsModelTypes";
export default {
  props: {
    owner: {
      type: Object,
      default: () => { },
    },
    mode: {
      type: String,
      default: "standalone",
    },
    contentClass: {
      type: [String, Array, Object],
      default: "xs12 md6",
    },
    hasContent: {
      type: Boolean,
      default: false,
    },
    disableDefaultActions: {
      type: Boolean,
      default: false,
    },
    col: {
      type: Boolean,
      default: true,
    },
    enableAddNew: {
      type: Boolean,
      default: true,
    },
    saveBtnIcon: {
      type: String,
      default: "save",
    },
    saveBtnText: {
      type: String,
      default: "Save Changes",
    },
    iconButton: {
      type: Boolean,
      default: true,
    },
    disableAddNewText: {
      type: Boolean,
      default: false,
    },
    goBack: {
      type: Function,
      default: null,
    },
    hideTitleWithBack: {
      type: Boolean,
      default: false,
    },
    altBackIcon: {
      type: Boolean,
      default: false,
    },
    hideBackButton: {
      type: Boolean,
      default: false,
    },
    alignAlertIcon: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [SupportsModelTypes],
  data () {
    return {
      buttonHover: false,
      buttonAddHover: false,
      addNewText: "Add New",
      isBackToDefault: false,
      backToDefaultPath: null,
    };
  },
  created () {
    if (this.$route.params && this.$route.params.isBackToDefault !== undefined) {
      this.isBackToDefault = this.$route.params.isBackToDefault;
      if (this.$route.params.defaultFeaturePath !== undefined) {
        this.backToDefaultPath = this.$route.params.defaultFeaturePath;
      }
    }
    this.modelType = this.type || (this.owner || this.$parent).modelType;
    this.modelTypeAlt = this.typeAlt || (this.owner || this.$parent).modelTypeAlt;
  },
  methods: {
    onSaveAndReset () {
      this.addNewText = "Saving...";
      this.owner.onSave().then((result) => {
        this.owner.resetForm();
        this.addNewText = "Add New";
      });
    },
    onGoBack () {
      if (this.isBackToDefault) {
        if (this.backToDefaultPath) {
          this.$router.push(this.backToDefaultPath);
        } else {
          this.$router.go(-1);
        }
      } else {
        if (this.goBack instanceof Function) {
          return this.goBack();
        }
        if(this.$router.length > 1) {
          this.$router.back();
        } else if((this.owner.modelType).toLowerCase().singularize() == "pipeline") {
          this.$router.push('/settings#pipelines');
        } else {
          this.navigateTo({ name: `${(this.owner.modelType).toLowerCase().singularize()}.index` });
        }
      }
    },
  },
};
</script>