<template>
  <div class="card card-default">
    <!-- Update Payment Method Heading -->
    <div class="card-header">
      <div class="float-left">
        Update Payment Method
      </div>
      <div class="float-right">
        <!-- Paypal Indicator -->
        <span v-if="billable.paypal_email">
          <i class="fa fa-btn fa-paypal" />
          @{{ billable.paypal_email }}
        </span>
        <!-- Credit Card Indicator -->
        <span v-if="billable.card_last_four">
          <i class="['fa', 'fa-btn', cardIcon]" />
          ************@{{ billable.card_last_four }}
        </span>
      </div>
      <div class="clearfix" />
    </div>
    <div class="card-body">
      <!-- Payment Method Update Success Message -->
      <div
        v-if="form.successful"
        class="alert alert-success"
      >
        Your payment method has been updated.
      </div>
      <!-- Generic 500 Level Error Message / Braintree Threw Exception -->
      <div
        v-if="form.errors.has('form')"
        class="alert alert-danger"
      >
        We had trouble updating your payment method. It's possible your payment provider is preventing us from charging the payment method. Please contact your payment provider or customer support.
      </div>
      <form role="form">
        <!-- Braintree Container -->
        <div
          id="braintree-payment-method-container"
          class="m-b-md"
        />
        <!-- Update Button -->
        <div class="form-group">
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="form.busy"
          >
            <span v-if="form.busy">
              <VIcon>refresh</VIcon>Updating
            </span>
            <span v-else>
              Update
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import SparkForm from "@/config/spark";
import BraintreeMixin from "@/mixins/BrainTree";
export default {
  /**
   * Load mixins for the component.
   */
  mixins: [BraintreeMixin],

  /**
   * The component's data.
   */
  data() {
    return {
      form: new SparkForm({
        braintree_type: "",
        braintree_token: ""
      })
    };
  },

  computed: {
    /**
     * Get the URL for the payment method update.
     */
    urlForUpdate() {
      return !this.$spark.usesTeams
        ? "/settings/payment-method"
        : `/settings/${Spark.teamsPrefix}/${this.team.id}/payment-method`;
    },

    /**
     * Get the proper brand icon for the customer's credit card.
     */
    cardIcon() {
      if (!this.billable.card_brand) {
        return "fa-credit-card";
      }

      switch (this.billable.card_brand) {
        case "American Express":
          return "fa-cc-amex";
        case "Diners Club":
          return "fa-cc-diners-club";
        case "Discover":
          return "fa-cc-discover";
        case "JCB":
          return "fa-cc-jcb";
        case "MasterCard":
          return "fa-cc-mastercard";
        case "Visa":
          return "fa-cc-visa";
        default:
          return "fa-credit-card";
      }
    }
  },

  /**
   * Prepare the component.
   */
  mounted() {
    this.braintree(
      "braintree-payment-method-container",
      this.braintreeCallback
    );
  },

  methods: {
    /**
     * Update the entity's card information.
     */
    update() {
      Spark.put(this.urlForUpdate, this.form).then(() => {
        Bus.$emit("updateUser");
        Bus.$emit("updateTeam");

        this.resetBraintree(
          "braintree-payment-method-container",
          this.braintreeCallback
        );
      });
    },

    /**
     * The Braintree payment method received callback.
     */
    braintreeCallback(response) {
      this.form.braintree_type = response.type;
      this.form.braintree_token = response.nonce;

      this.update();
    }
  }
};
</script>