var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VRow',{attrs:{"column":""}},[_c('VCol',{staticStyle:{"flex":"0 0 64px"}},[_c('IndexHeader',{attrs:{"mode":"standalone","can-create":_vm.$isUserAdmin},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h4',[_vm._v(_vm._s(_vm.featureName('Pipelines')))])]},proxy:true},{key:"create-action",fn:function(){return [(_vm.$isUserAdmin)?_c('ExpandableButton',{staticClass:"wethrive",attrs:{"large":"","icon":"add","to":{
            name: `${_vm.modelType.singularize()}.create`,
            params: {
              team: _vm.$team.slug,
            },
          },"tooltip-text":`Add ${_vm.featureName(_vm.modelType.capitalize())}`}}):_vm._e()]},proxy:true},{key:"actions-left",fn:function(){return [(!_vm.$isUserStudent)?_c('ExpandableButton',{attrs:{"icon":"","text":"Export","button-class":"ml-1","color":"info","beta-feature":"pipeline_submitted_export","depressed":true,"large":true,"flex":true,"block":_vm.$vuetify.breakpoint.smAndDown,"beta-options":{
            asIcon: false,
            overlap: true,
            offsetY: '15',
            offsetX: '15',
            class: _vm.isBetaFeature('pipeline_submitted_export') ? 'mr-2' : ''
          },"to":{
            hash: '#pipelines-export'
          },"tooltip-text":`Export Submitted ${_vm.featureName('Pipelines').toLowerCase()}`}}):_vm._e()]},proxy:true}])})],1),_c('VCol',{staticStyle:{"flex":"1 1 auto"}},[_c('VTabs',{staticClass:"d-none",attrs:{"grow":"","lazy":"","slider-color":"primary"}},_vm._l((_vm.tabs),function(tab,key){return _c('VTab',{key:key,attrs:{"to":{
          hash: tab.url
        },"lazy":""}},[_c('BetaBadge',{attrs:{"enable-tooltip":"","is-beta":_vm.isBetaFeature(tab.feature)}},[_vm._v(" "+_vm._s(tab.title)+" ")])],1)}),1),_c('VTabsItems',{attrs:{"touchless":"","value":_vm.activeUrlTab}},[_c('VTabItem',[_c('Data',{ref:"data",attrs:{"existing-config":_vm.config}})],1),_c('VTabItem',[_c('Submitted',{ref:"submitted",attrs:{"mode":"embedded"}})],1),(false && _vm.$isUserAdmin || _vm.$isUserMentor)?_c('VTabItem',[_c('Import',{ref:"imported"})],1):_vm._e(),(_vm.$isUserAdmin || _vm.$isUserMentor)?_c('VTabItem',[_c('Export',{ref:"exported"})],1):_vm._e()],1),(_vm.currentModel)?_c('FormDialog',{attrs:{"fullscreen":"","current-model":_vm.currentModel,"is-dialog-open":_vm.dialogs.form,"title":_vm.currentModel ? _vm.currentModel.title : '',"model-type":_vm.modelType},on:{"toggle:form:dialog":_vm.onToggleFormDialog,"deleteItem":_vm.onDeleteItem}},[(_vm.$isUserAdmin)?_c('Save',{ref:"saveForm",attrs:{"mode":"dialog","id":_vm.currentModel ? _vm.currentModel.id : null}}):_c('Submit',{ref:"saveForm",attrs:{"mode":"dialog","pipeline":_vm.currentModel}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }