import api from '../../services/team';
export default function (helper) {

  // Team types
  const TYPES = {
    USERS_APPROVE_USER: 'USERS_APPROVE_USER',
    USERS_REQUEST_ACCESS: 'USERS_REQUEST_ACCESS',
    USERS_REMOVE_USER: 'USERS_REMOVE_USER',
    TEAM_ACCEPT_INVITE: 'TEAM_ACCEPT_INVITE',
    TEAM_INVITE_USER: 'TEAM_INVITE_USER',
    TEAM_REJECT_INVITE: 'TEAM_REJECT_INVITE',
    TEAM_GET_PENDING_INVITES: 'TEAM_GET_PENDING_INVITES',
    TEAM_GET_MAILED_INVITES: 'TEAM_GET_MAILED_INVITES',
    TEAM_SET_AUTH_TOKEN: 'TEAM_SET_AUTH_TOKEN',
    TEAM_GET_CONNECTED_ACCOUNTS: 'TEAM_GET_CONNECTED_ACCOUNTS',
    TEAM_GET_CONNECTED_ACCOUNT: 'TEAM_GET_CONNECTED_ACCOUNT',
    TEAM_DELETE_CONNECTED_ACCOUNT: 'TEAM_DELETE_CONNECTED_ACCOUNT',
    STORE_SAVE: "STORE_SAVE"
  };

  /**
   * Generated store module
   */
  helper.setApi(api);
  const types = helper.types('team');
  const state = helper.state({
    connected_accounts: []
  });
  const getters = helper.getters({
    hasConnectedAccounts: state => state.connected_accounts.length > 0
  });
  const actions = helper.actions({
    teamExists(context, params) {
      const slug = params.slug || params;
      helper.log().info('[Store: Teams]: Team Exists', slug)
      return helper.api().teamExists(params).then(result => result.data.data)
        .catch((error) => {
          helper.log().error('[Store: Teams: Team Exists]: Error', error)
          throw error
        })
    },
    approveUser(context, id) {
      helper.log().info('[Store: Teams]: Approve User', id);
      return helper.api().approveUser(id).then((response) => {
        helper.log().info(`[Store: Teams]: Approved User`, response);
        const data = response.data.data;
        context.commit(TYPES.USERS_APPROVE_USER, data);
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Teams]: Error Approving Users`, error);
        throw error;
      });
    },
    requestAccess(context, params) {
      helper.log().info('[Store: Teams]: Request Access', params);
      return helper.api().requestAccess(params).then((response) => {
        helper.log().info(`[Store: Teams]: Request Access`, response);
        const data = response.data.data;
        context.commit(TYPES.USERS_APPROVE_USER, data);
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Teams]: Error Requesting Access`, error);
        throw error;
      });
    },
    invite(context, params) {
      helper.log().info('[Store: Teams]: Invite', params);
      return helper.api().invite(params).then((response) => {
        helper.log().info(`[Store: Teams]: Invite`, response);
        const data = response.data.data;
        context.commit(TYPES.TEAM_INVITE_USER, data);
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Teams]: Error Inviting`, error);
        throw error;
      });
    },
    inviteToProgram(context, params) {
      helper.log().info('[Store: Teams]: Invite to Program', params);
      return helper.api().inviteToProgram(params).then((response) => {
        helper.log().info(`[Store: Teams]: Invite to Program`, response);
        const data = response.data.data;
        context.commit(TYPES.TEAM_INVITE_USER, data);
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Teams]: Error Inviting to Program`, error);
        throw error;
      });
    },
    bulkInviteToProgram(context, params) {
      helper.log().info('[Store: Teams]: Bulk Invite to Program', params);
      return helper.api().inviteUsers(params).then((response) => {
        helper.log().info(`[Store: Teams]: Bulk Invite to Program`, response);
        const data = response.data.data;
        context.commit(TYPES.TEAM_INVITE_USER, data);
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Teams]: Error Bulk Inviting to Program`, error);
        throw error;
      });
    },
    requestInvite(context, _params) {
      const params = _params.data || params;
      helper.log().info('[Store: Teams]: Request Invite', params);
      return helper.api().requestInvite(params).then((response) => {
        helper.log().info(`[Store: Teams]: Request Invite`, response);
        const data = response.data.data;
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Teams]: Error Requesting Invite`, error);
        throw error;
      });
    },
    leave(context, team) {
      helper.log().info('[Store: Team]: Leave Team', team);
      return helper.api().leave(team).then((response) => {
        helper.log().info(`[Store: Team]: Left Team`, response);
        const data = response.data.data;
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Team]: Error Leaving Team`, error);
        throw error;
      });
    },
    verifyInvite(context, params) {
      helper.log().info('[Store: Teams]: Verify Invite', params);
      return helper.api().verifyInvite(params).then((response) => {
        helper.log().info(`[Store: Teams]: Verify Invite`, response);
        const data = response.data.data;
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Teams]: Error Verifying Invite`, error);
        throw error;
      });
    },
    getPendingInvites(context, params) {
      helper.log().info('[Store: Teams]: Get Pending Invites', params);
      return helper.api().getPendingInvites(params).then((response) => {
        helper.log().info(`[Store: Teams]: Got Pending Invites`, response);
        const data = response.data.data;
        context.commit(TYPES.TEAM_GET_PENDING_INVITES, data);
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Teams]: Error Geting Pending Invites`, error);
        throw error;
      });
    },
    invitations(context, params) {
      helper.log().info('[Store: Teams]: Get Mailed Invites', params);
      return helper.api().invitations(params).then((response) => {
        helper.log().info(`[Store: Teams]: Got Pending Invites`, response);
        const data = response.data.data;
        context.commit(TYPES.TEAM_GET_MAILED_INVITES, data);
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Teams]: Error Getting Mailed Invites`, error);
        throw error;
      });
    },
    acceptInvite(context, params) {
      helper.log().info('[Store: Teams]: Accept Invite', params);
      return helper.api().acceptInvite(params).then((response) => {
        helper.log().info(`[Store: Teams]: Accept Invite`, response);
        const data = response.data.data;
        context.commit(TYPES.TEAM_ACCEPT_INVITE, data);
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Teams]: Error Accepting Invite`, error);
        throw error;
      });
    },
    rejectInvite(context, params) {
      helper.log().info('[Store: Teams]: Reject Invite', params);
      return helper.api().rejectInvite(params).then((response) => {
        helper.log().info(`[Store: Teams]: Reject Invite`, response);
        const data = response.data.data;
        context.commit(TYPES.TEAM_REJECT_INVITE, data);
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Teams]: Error Rejecting Invite`, error);
        throw error;
      });
    },
    deleteInvite(context, params) {
      const id = params.id || params;
      helper.log().info('[Store: Teams]: Delete Invite', id);
      return helper.api().deleteInvite(id).then((response) => {
        helper.log().info(`[Store: Teams]: Delete Invite`, response);
        const data = response.data.data;
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Teams]: Error Deleting Invite`, error);
        throw error;
      });
    },
    deleteMember(context, id) {
      helper.log().info('[Store: Teams]: Delete Member', id);
      return helper.api().deleteMember(id).then((response) => {
        helper.log().info(`[Store: Teams]: Delete Members`, response);
        const data = response.data.data;
        context.commit(TYPES.USERS_REMOVE_USER, {
          params: id,
          data
        });
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Teams]: Error Deleting Members`, error);
        throw error;
      });
    },
    setAuthToken(context, params) {
      helper.log().info('[Store: Teams]: Set Auth Token', params);
      return helper.api().setAuthToken(params).then((response) => {
        helper.log().info(`[Store: Teams]: Set Auth Token`, response);
        const data = response.data.data;
        context.commit(TYPES.TEAM_SET_AUTH_TOKEN, data);
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Teams]: Error Setting Auth Token`, error);
        throw error;
      });
    },
    async getConnectedAccounts(context, params) {
      helper.log().info('[Store: Teams]: Get Connected Accounts', params);
      return await helper.api().getConnectedAccounts(params).then((response) => {
        helper.log().info(`[Store: Teams]: Got Connected Accounts`, response);
        const data = response.data.data;
        context.commit(TYPES.TEAM_GET_CONNECTED_ACCOUNTS, data);
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Teams]: Error Get Connected Accounts`, error);
        throw error;
      });
    },
    async getConnectedAccount(context, type) {
      helper.log().info('[Store: Teams]: Get Connected Account', type);
      let account = null;
      if (context.getters.hasConnectedAccounts &&
        (account = context.state.connected_accounts.filter(e => e instanceof Object).find(existing => existing.slug = type)) instanceof Object) {
        helper.log().info(`[Store: Teams]: Got Local Connected Account`, account);
        return account;
      } else {
        return await helper.api().getConnectedAccount(type).then((response) => {
          helper.log().info(`[Store: Teams]: Got Connected Account`, response);
          const data = response.data.data;
          context.commit(TYPES.TEAM_GET_CONNECTED_ACCOUNT, data);
          return data;
        }).catch((error) => {
          helper.log().info(`[Store: Teams]: Error Get Connected Accounts`, error);
          throw error;
        });
      }
    },
    async deleteConnectedAccount(context, account) {
      helper.log().info('[Store: Teams]: Delete Connected Account', account.slug);
      return await helper.api().deleteConnectedAccount(account.slug).then((response) => {
        helper.log().info(`[Store: Teams]: Delete Connected Account`, response);
        const data = response.data.data;
        context.commit(TYPES.TEAM_DELETE_CONNECTED_ACCOUNT, {
          params: account.id,
          result: response.data.data
        });
      }).catch((error) => {
        helper.log().info(`[Store: Teams]: Error Delete Connected Accounts`, error);
        throw error;
      });
    },
    contentVisibility(context, params) {
      helper.log().info('[Store: Teams]: Program content visibility', params);
      return helper.api().setContentVisibility(params).then((response) => {
        helper.log().info(`[Store: Teams]: Set Program content visibility`, response);
        const data = response.data.data;
        return data;
      }).catch((error) => {
        helper.log().info(`[Store: Teams]: Error Setting Program content visibility`, error);
        throw error;
      });
    },
  }, 'Team');
  const mutations = helper.mutations({
    [TYPES.USERS_REMOVE_USER](state, data) {
      this.commit('users/STORE_DELETE', data, {
        root: true
      });
    },
    [TYPES.USERS_APPROVE_USER](state, data) {
      this.commit('users/USERS_APPROVE_USER', data, {
        root: true
      });
    },
    [TYPES.USERS_REQUEST_ACCESS](state, data) {},
    [TYPES.TEAM_ACCEPT_INVITE](state, data) {},
    [TYPES.TEAM_REJECT_INVITE](state, data) {},
    [TYPES.TEAM_INVITE_USER](state, data) {},
    [TYPES.TEAM_GET_PENDING_INVITES](state, data) {},
    [TYPES.TEAM_GET_MAILED_INVITES](state, data) {},
    [TYPES.TEAM_SET_AUTH_TOKEN](state, data) {},
    [TYPES.TEAM_GET_CONNECTED_ACCOUNTS](state, data) {
      state.connected_accounts = data;
    },
    [TYPES.TEAM_DELETE_CONNECTED_ACCOUNT](state, data) {
      helper.utils.removeFromStateData(state.connected_accounts, data.params, true);
    },
    [TYPES.TEAM_GET_CONNECTED_ACCOUNT](state, data) {
      helper.utils.addToStateData(state.connected_accounts, data, true);
    },
    [TYPES.STORE_SAVE](state, data) {
      // Only update if this is a new item
      if (data.context) {
        helper.utils.updateStats(data.context, data.params.id ? 0 : 1, data.type);
      }
      helper.utils.addToStateData(state, data.result);
      this.commit('user/UPDATE_TEAM', data.result, {
        root: true
      });
      return data.result;
    },
  }, types, state);

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}