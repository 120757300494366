<template>
  <VCard>
    <VCardTitle>
      <div
        class="float-left"
        :class="{'btn-table-align': hasMonthlyAndYearlyPlans}"
      >
        Resume Subscription
      </div>
      <!-- Interval Selector Button Group -->
      <div class="float-right">
        <div
          v-if="hasMonthlyAndYearlyPlans"
          class="btn-group btn-group-sm"
        >
          <!-- Monthly Plans -->
          <VBtn
            type="light"
            :class="{'active': showingMonthlyPlans}"
            @click="showMonthlyPlans"
          >
            Monthly
          </VBtn>
          <!-- Yearly Plans -->
          <VBtn
            type="light"
            :class="{'active': showingYearlyPlans}"
            @click="showYearlyPlans"
          >
            Yearly
          </VBtn>
        </div>
      </div>
      <div class="clearfix" />
    </VCardTitle>
    <VCardText>
      <!-- Plan Error Message - In General Will Never Be Shown -->
      <VAlert
        v-if="planForm.errors.has('plan')"
        :value="true"
        color="error"
      >
        {{ planForm.errors.get('plan') }}
      </VAlert>
      <!-- Cancellation Information -->
      <div class="m-4">
        <p>
          You have cancelled your subscription to the
          <strong>
            {{ activePlan.name }} {{
              activePlan.interval.capitalize() }}
          </strong> plan.
        </p>
      </div>
      <div class="m-4">
        <p>
          The benefits of your subscription will continue until your current billing period ends on
          <strong>{{ $timezone.formatDateTime(activeSubscription.ends_at) }}</strong>. You may resume your subscription at no
          extra cost until the end of the billing period.
        </p>
      </div>
      <!-- European VAT Notice -->
      <template v-if="$spark.collectsEuropeanVat">
        <p class="m-4">
          All subscription plan prices include applicable VAT.
        </p>
      </template>
      <VList>
        <VListGroup
          v-for="(plan, key) in plansForActiveInterval"
          :key="key"
        >
          <VListItem slot="activator">
            <VListItemContent>
              <VListItemTitle>{{ plan.name }}</VListItemTitle>
              <VListItemSubtitle>
                {{ $spark.currencySymbol }}{{ priceWithTax(plan) }}
                {{ plan.type == 'user' && $spark.chargesUsersPerSeat ? '/ '+ $spark.seatName : '' }}
                {{ plan.type == 'user' && $spark.chargesUsersPerTeam ? '/ '+ 'Team' : '' }}
                {{ plan.type == 'team' && $spark.chargesTeamsPerSeat ? '/ '+ $spark.teamSeatName : ''
                }}
                {{ plan.type == 'team' && $spark.chargesTeamsPerMember ? '/ '+ $team.user_role_name : ''
                }}
                / {{ plan.interval.capitalize() }}
              </VListItemSubtitle>
            </VListItemContent>
            <VListItemAction>
              <VBtn
                :color="isActivePlan(plan) ? 'success': 'warning'"
                :disabled="selectingPlan !== null"
                :loading="selectingPlan !== null"
                @click="updateSubscription(plan)"
              >
                <span v-if="selectingPlan === plan">
                  <VIcon>refresh</VIcon>Resuming
                </span>
                <span v-if="!isActivePlan(plan) && selectingPlan !== plan">
                  Switch
                </span>
                <span v-if="isActivePlan(plan) && selectingPlan !== plan">
                  Resume
                </span>
              </VBtn>
            </VListItemAction>
          </VListItem>
          <VList>
            <VListItem
              v-for="(feature, featureKey) in plan.features"
              :key="featureKey"
            >
              <VListItemContent>
                <VListItemTitle class="text-sm-center">
                  {{ feature }}
                </VListItemTitle>
              </VListItemContent>
            </VListItem>
          </VList>
        </VListGroup>
      </VList>
    </VCardText>
  </VCard>
</template>
<script>
import PlansMixin from "@/mixins/Plans";
import SubscriptionMixin from "@/mixins/Subscriptions";

export default {
  /**
   * Load mixins for the component.
   */
  mixins: [PlansMixin, SubscriptionMixin],
  props: {
    plans: {
      type: Array,
      default: () => []
    }
  },

  /**
   * Prepare the component.
   */
  created() {
    if (this.onlyHasYearlyPlans) {
      this.showYearlyPlans();
    }
  },

  methods: {
    /**
     * Show the plan details for the given plan.
     *
     * We'll ask the parent subscription component to display it.
     */
    showPlanDetails(plan) {
      this.$parent.$emit("showPlanDetails", plan);
    },

    /**
     * Get the plan price with the applicable VAT.
     */
    priceWithTax(plan) {
      return plan.price + plan.price * (this.billable.tax_rate / 100);
    }
  }
};
</script>