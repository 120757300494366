import { render, staticRenderFns } from "./Stats.vue?vue&type=template&id=243e7d41&"
import script from "./Stats.vue?vue&type=script&lang=js&"
export * from "./Stats.vue?vue&type=script&lang=js&"
import style0 from "./Stats.vue?vue&type=style&index=0&id=243e7d41&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports